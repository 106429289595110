import styled from 'styled-components';

export const modalCustomStyles = {
  content: {
    display: 'contents',
    borderWidth: 0,
    padding: 0,
    margin: 0,
  },
  overlay: {
    zIndex: 10,
    background: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 16px;
  border-radius: 12px;

  @media (max-width: 816px) {
    margin: 0 16px;
  }
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 16px;

  :first-child {
    padding-top: 0;
  }
`;

export const CustomCheckbox = styled.input`
  margin: 0 16px 0 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #161e41;
  opacity: 0.4;

  :checked {
    opacity: 1;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000;
  user-select: none;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

export const AddMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 52px;
  background: #161e41;
  border-radius: 8px;
  margin-top: auto;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};

  :hover {
    opacity: ${(props) => (props.disabled ? '0.7' : '0.9')};
  }

  @media (max-width: 768px) {
    max-width: initial;
  }
`;

export const AddText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 8px;
`;

export const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 320px;
  max-height: 320px;
  overflow-y: scroll;
  padding: 16px;
  margin: 16px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const WrapperLoader = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const CloseWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`;
