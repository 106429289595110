export const getCustomFieldsArraysEdit = ({
  sections,
  languages,
  customFields,
}) => {
  const getSectionId = (name) => {
    let sectionId;
    sections?.map((sec) => {
      if (sec?.alias === name) {
        sectionId = sec?.id;
      }
    });
    return sectionId;
  };

  const getMissingSection = () => {
    const sectionAliases = customFields
      ?.map((item) => item.section.alias)
      ?.filter((value, index, self) => self.indexOf(value) === index);

    const missingSectionObj = sections?.find(
      (item) => !sectionAliases?.includes(item.alias),
    );

    const missingSectionArr = languages?.map((lang) => {
      return {
        key: '',
        value: '',
        languageId: lang.id,
        sectionId: missingSectionObj ? missingSectionObj.id : null,
      };
    });

    return missingSectionArr;
  };

  const missingSectionArr = getMissingSection();

  const customFieldsArrays = sections
    ?.map((item) => item?.alias) // array from sections names for get default object for custom fields
    .reduce((result, item) => {
      languages?.forEach((lang) => {
        if (customFields && missingSectionArr) {
          [...customFields, ...missingSectionArr]?.forEach((field) => {
            const itemKey = `${item}${lang?.iso}`;
            if (
              field.languageId === lang.id &&
              getSectionId(item) === field.sectionId
            ) {
              if (result[itemKey]) {
                result[itemKey] = [...result[itemKey], field];
              } else {
                result[itemKey] = [field];
              }
            }
          });
        }
      });
      return result;
    }, {});

  return customFieldsArrays;
};

export const getCustomSampleFieldsEdit = ({ languages, template }) => {
  const result = {};
  languages?.forEach((lang) => {
    result[`key${lang?.iso}`] = template?.fields?.[0]?.find(
      (item) => item?.languageId === lang?.id,
    )?.key;
    result[`value${lang?.iso}`] = template?.fields?.[0]?.find(
      (item) => item?.languageId === lang?.id,
    )?.value;
  });
  return result;
};
