import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { getFieldName } from 'utils';
import {
  customStylesSelect,
  Label,
  LeftIconWrapper,
  SelectBlock,
  SelectWrapper,
} from './styles';

const Select = ({
  label,
  name,
  options,
  leftIcon,
  placeholder = 'Выберите опцию',
  isSearchable = false,
  isClearable = false,
  menuOnTop = false,
  defaultValue = options?.[0],
  disabled = false,
  onInputChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  return (
    <SelectWrapper>
      <Label>{label}</Label>
      <SelectBlock>
        {leftIcon && <LeftIconWrapper>{leftIcon}</LeftIconWrapper>}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ReactSelect
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
                options={options}
                defaultValue={defaultValue}
                styles={customStylesSelect}
                hasError={hasError}
                leftIcon={leftIcon}
                isSearchable={isSearchable}
                menuOnTop={menuOnTop}
                isClearable={isClearable}
                isDisabled={disabled}
                onInputChange={onInputChange}
              />
            );
          }}
        />
      </SelectBlock>
    </SelectWrapper>
  );
};

export default Select;
