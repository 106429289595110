export const EditPhotoIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#161E41" />
      <path
        d="M15.864 12.843L15.157 12.136L10.5 16.793V17.5H11.207L15.864 12.843ZM16.571 12.136L17.278 11.429L16.571 10.722L15.864 11.429L16.571 12.136ZM11.621 18.5H9.5V16.3785L16.2175 9.66104C16.3113 9.56731 16.4384 9.51465 16.571 9.51465C16.7036 9.51465 16.8307 9.56731 16.9245 9.66104L18.339 11.0755C18.4327 11.1693 18.4854 11.2965 18.4854 11.429C18.4854 11.5616 18.4327 11.6888 18.339 11.7825L11.6215 18.5H11.621Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="white" />
    </svg>
  );
};
