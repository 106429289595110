import styled from 'styled-components';

export const WrapperEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  gap: 8px;
`;

export const LabelEditor = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9b9ea7;
`;

export const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dbdfe8;
  border-radius: 6px;
  padding: 8px;
  min-height: 200px;
`;

export const ControlsWrapper = styled.div`
  border-bottom: 1px solid #dbdfe8;
  padding: 4px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
`;

export const ControlButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  background: #161e41;
  color: #fff;
  border-radius: 6px;
  align-items: center;
  border: none;
  cursor: pointer;
  height: 31px;
`;
