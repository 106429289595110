export const ConfirmIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 12.5L15.4992 15H18V16.6667H15.4992L15.5 19.1667H13.8333L13.8325 16.6667H11.3333V15H13.8325L13.8333 12.5H15.5ZM9.66667 15V16.6667H3V15H9.66667ZM18 9.16667V10.8333H3V9.16667H18ZM18 3.33333V5H3V3.33333H18Z"
        fill="white"
      />
    </svg>
  );
};
