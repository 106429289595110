export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_32)">
        <path
          d="M12.6667 14H3.33333C3.15652 14 2.98695 13.9298 2.86193 13.8047C2.7369 13.6797 2.66667 13.5101 2.66667 13.3333L2.66667 7.33333H0.666667L7.55133 1.07467C7.67407 0.962985 7.83405 0.901095 8 0.901095C8.16595 0.901095 8.32593 0.962985 8.44867 1.07467L15.3333 7.33333H13.3333V13.3333C13.3333 13.5101 13.2631 13.6797 13.1381 13.8047C13.013 13.9298 12.8435 14 12.6667 14ZM4 12.6667H12V6.10467L8 2.46867L4 6.10467L4 12.6667ZM6 6.66667H10V10.6667H6V6.66667ZM7.33333 8V9.33333H8.66667V8H7.33333Z"
          fill="#128555"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_32">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
