export const sampleTagsInfo = ({ data, languages, isEditMode, iconData }) => {
  const {
    optionGeneral,
    tagsInner,
    tagsAboutHome,
    tagsAboutObject,
    tagsCommunications,
    tagsMap,
    tagsRules,
    sell,
    rent,
    daily,
    apartments,
    house,
    commercial,
    plot,
  } = data;

  const typesValues = {
    optionGeneral,
    tagsInner,
    tagsAboutHome,
    tagsAboutObject,
    tagsCommunications,
    tagsMap,
    tagsRules,
  };
  const announcementTypesValues = { sell, rent, daily };
  const propertyTypesValues = { apartments, house, commercial, plot };

  const getTrueValues = (values) => {
    return Object.keys(values).filter((key) => values[key] === true);
  };

  if (data) {
    return isEditMode
      ? {
          id: data.id,
          name: data.templateName,
          tags: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
          iconToken: !data.icon ? null : iconData?.[0]?.token || data.iconToken,
          types: getTrueValues(typesValues),
          announcementTypes: getTrueValues(announcementTypesValues),
          propertyTypes: getTrueValues(propertyTypesValues),
        }
      : {
          name: data.templateName,
          tags: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
          iconToken: iconData?.[0]?.token,
          types: getTrueValues(typesValues),
          announcementTypes: getTrueValues(announcementTypesValues),
          propertyTypes: getTrueValues(propertyTypesValues),
        };
  }
};
