import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    background: #fff;
  }
  a {
    text-decoration: none;
  }
  *,
  *::before,
  *::after {
  box-sizing: border-box;
  font-family: 'Jost', sans-serif;
}

`;

export default Global;
