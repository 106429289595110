export const getSampleTagsFields = ({ languages }) => {
  const result = {};
  languages?.forEach((lang) => {
    result[`value${lang?.iso}`] = '';
  });
  return result;
};

export const getSampleTagsFieldsEdit = ({ languages, template }) => {
  const result = {};
  languages?.forEach((lang) => {
    result[`value${lang?.iso}`] = template?.tags?.[0]?.find(
      (item) => item?.languageId === lang?.id,
    )?.value;
  });
  return result;
};
