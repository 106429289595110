import { getInitFields } from 'utils';
import { getInitEditSeoMetatagsFields } from 'utils/getInitEditSeoMetatagsFields';

export const getSeoMetatagsFieldsData = ({ metaTag, languages }) => {
  // inital fields
  const titles = getInitFields({ languages, name: 'title' });
  const descriptions = getInitFields({ languages, name: 'description' });
  const h1s = getInitFields({ languages, name: 'h1' });
  const texts = getInitFields({ languages, name: 'text' });

  const ADD_INITIAL_VALUES_SEO = {
    isIndex: false,
    url: '',
  };

  const initValuesFull = {
    ...ADD_INITIAL_VALUES_SEO,
    ...titles,
    ...descriptions,
    ...h1s,
    ...texts,
  };

  // edit fields

  const titlesEdit = getInitEditSeoMetatagsFields({
    metaTag,
    name: 'title',
    languages,
  });

  const descriptionsEdit = getInitEditSeoMetatagsFields({
    metaTag,
    name: 'description',
    languages,
  });

  const h1sEdit = getInitEditSeoMetatagsFields({
    metaTag,
    name: 'h1',
    languages,
  });

  const textsEdit = getInitEditSeoMetatagsFields({
    metaTag,
    name: 'text',
    languages,
  });

  const EDIT_INITIAL_VALUES_SEO = {
    id: metaTag?.id,
    url: metaTag?.url,
    isIndex: metaTag?.isIndex,
  };

  const editValuesFull = {
    ...EDIT_INITIAL_VALUES_SEO,
    ...titlesEdit,
    ...descriptionsEdit,
    ...h1sEdit,
    ...textsEdit,
  };

  return {
    initValuesFull,
    editValuesFull,
  };
};
