import styled from 'styled-components';

export const customStylesSelect = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    color: '#161e41',
  }),
  control: (styles, state) => ({
    ...styles,
    padding: state.selectProps.leftIcon
      ? '16px 16px 16px 34px'
      : '16px 16px 16px 12px',
    width: '100%',
    height: '52px',
    border: state.selectProps.hasError ? '1px solid red' : '1px solid #dbdfe8',
    borderRadius: '6px',
    cursor: 'pointer',

    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.selectProps.hasError
        ? '1px solid red'
        : '1px solid #dbdfe8',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 8px 0 0',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#161E41',
    padding: 0,
  }),
  menu: (styles, state) => ({
    ...styles,
    zIndex: 2,
    bottom: state.selectProps.menuOnTop ? '50px' : 'auto',
    top: state.selectProps.menuOnTop ? 'auto' : '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#EEF2FA' : '',
    color: '#161E41',
    '&:hover': {
      backgroundColor: '#EEF2FA',
    },
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#dbdfe8',
  }),
};

export const CustomSelect = styled.select`
  display: flex;
  padding: 16px 12px;
  width: 100%;
  height: 52px;
  border: 1px solid #dbdfe8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #161e41;

  :focus-visible {
    outline: none;
  }
`;

export const Label = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #9b9ea7;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  margin-top: 16px;
`;

export const SelectBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const LeftIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 12px;
  top: 18px;
`;
