export const ViberIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9176 3.31835C19.3829 2.82574 17.2184 1.255 12.3927 1.234C12.3927 1.234 6.70383 0.892941 3.93305 3.43633C2.39184 4.97744 1.84865 7.23874 1.78969 10.039C1.73072 12.8393 1.65914 18.0859 6.71644 19.5093H6.72061L6.71644 21.6821C6.71644 21.6821 6.68278 22.5622 7.26384 22.739C7.96289 22.958 8.37553 22.2885 9.04505 21.5684C9.41142 21.1726 9.91669 20.5915 10.2999 20.1493C13.7612 20.4399 16.4184 19.7746 16.7215 19.6778C17.4206 19.4504 21.3746 18.945 22.0146 13.6983C22.6799 8.28302 21.6946 4.86377 19.9176 3.31835Z"
        fill="white"
      />
      <path
        d="M20.5029 13.3024C19.9597 17.6816 16.7552 17.9596 16.1657 18.1491C15.913 18.2291 13.576 18.8102 10.641 18.6207C10.641 18.6207 8.4513 21.2609 7.76913 21.9474C7.54596 22.1705 7.30174 22.1494 7.30596 21.7073C7.30596 21.4168 7.32279 18.0986 7.32279 18.0986C7.31857 18.0986 7.31857 18.0986 7.32279 18.0986C3.03607 16.9111 3.28873 12.4433 3.33504 10.1063C3.38135 7.76927 3.82352 5.8533 5.1289 4.5648C7.47438 2.43827 12.3043 2.75411 12.3043 2.75411C16.3846 2.77094 18.3385 4.00047 18.7933 4.41316C20.2965 5.70171 21.063 8.78412 20.5029 13.3024Z"
        fill="#834995"
      />
      <path
        d="M14.6497 9.90011C14.6665 10.2622 14.1233 10.2875 14.1065 9.92532C14.0601 8.99893 13.6264 8.54842 12.7337 8.49789C12.3716 8.47679 12.4053 7.93361 12.7632 7.9547C13.9381 8.01784 14.5908 8.69158 14.6497 9.90011Z"
        fill="white"
      />
      <path
        d="M15.5046 10.3757C15.5467 8.59029 14.4309 7.19229 12.3127 7.03652C11.9548 7.01126 11.9927 6.46802 12.3507 6.49329C14.793 6.67019 16.0941 8.35033 16.0478 10.3884C16.0436 10.7505 15.4962 10.7337 15.5046 10.3757Z"
        fill="white"
      />
      <path
        d="M17.4837 10.9401C17.4878 11.3023 16.9404 11.3065 16.9404 10.9444C16.9152 7.5125 14.6287 5.64285 11.8537 5.6218C11.4957 5.61758 11.4957 5.07861 11.8537 5.07861C14.9571 5.09966 17.4542 7.24297 17.4837 10.9401Z"
        fill="white"
      />
      <path
        d="M17.0079 15.071V15.0795C16.5531 15.8796 15.7025 16.7639 14.8266 16.4818L14.8182 16.4691C13.9297 16.2207 11.8369 15.1427 10.5147 14.0899C9.83252 13.5509 9.20927 12.9151 8.72923 12.3045C8.29554 11.7613 7.85763 11.117 7.43234 10.3422C6.53543 8.72101 6.33752 7.99675 6.33752 7.99675C6.05538 7.12089 6.93546 6.27025 7.73969 5.81551H7.74818C8.13555 5.61334 8.5061 5.68075 8.75454 5.97967C8.75454 5.97967 9.27673 6.60283 9.49985 6.91033C9.71041 7.19664 9.99255 7.65559 10.1399 7.91247C10.3968 8.37142 10.2367 8.83886 9.98412 9.0325L9.4788 9.43679C9.22198 9.64309 9.25563 10.0263 9.25563 10.0263C9.25563 10.0263 10.0052 12.8603 12.8054 13.5761C12.8054 13.5761 13.1887 13.6098 13.3949 13.353L13.7992 12.8477C13.9929 12.595 14.4604 12.435 14.9193 12.6919C15.5383 13.0413 16.3257 13.5846 16.8479 14.0773C17.1426 14.3173 17.21 14.6837 17.0079 15.071Z"
        fill="white"
      />
    </svg>
  );
};
