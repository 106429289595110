import { useFormContext } from 'react-hook-form';
import { CheckboxWrapper, CustomCheckbox, Label } from './styles';

const Checkbox = ({ label, name, isTop }) => {
  const { register } = useFormContext();

  return (
    <CheckboxWrapper isTop={isTop}>
      <Label>
        <CustomCheckbox {...register(name)} name={name} type="checkbox" />
        {label}
      </Label>
    </CheckboxWrapper>
  );
};

export default Checkbox;
