import { annoucementsByLanguage } from 'connectors/annoucementsByLanguage';
import { annoucementsPagination } from 'constants';
import { useEffect, useState } from 'react';

export const useAnnouncementsPagination = ({
  announcements,
  fetchNextPage,
}) => {
  const [offset, setOffset] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const paginatedAnnouncementsData = () => {
    let result = [];
    announcements?.pages?.forEach((item) => {
      result.push(...item.rows);
    });
    return result;
  };

  const annoucementsRU = annoucementsByLanguage({
    announcements: paginatedAnnouncementsData(),
  });

  const isMoreAnnouncement =
    announcements?.pages[0]?.count > annoucementsRU.length;

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      isMoreAnnouncement
    ) {
      setIsDataLoading(true);
    }
  };

  useEffect(() => {
    if (isDataLoading && isMoreAnnouncement) {
      setOffset((prev) => {
        return prev + annoucementsPagination.limit;
      });
    }
  }, [isDataLoading]);

  useEffect(() => {
    offset > 0 &&
      fetchNextPage({
        pageParam: {
          limit: annoucementsPagination.limit,
          offset: offset,
        },
      }).then(() => {
        setIsDataLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    if (isMoreAnnouncement) {
      document.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [announcements?.pages[0]?.count, annoucementsRU, isMoreAnnouncement]);

  return { annoucementsRU };
};
