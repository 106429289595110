import styled from 'styled-components';

export const ArrowsBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 52px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
`;

export const OrderTop = styled.div`
  cursor: pointer;
  display: flex;
`;

export const OrderBottom = styled.div`
  cursor: pointer;
  display: flex;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

export const TrashBlock = styled.div`
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
