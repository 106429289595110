export const CloseIcon = ({
  fill = '#161E41',
  width = '20',
  height = '20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.82167L14.125 4.69667L15.3033 5.875L11.1783 10L15.3033 14.125L14.125 15.3033L10 11.1783L5.875 15.3033L4.69667 14.125L8.82167 10L4.69667 5.875L5.875 4.69667L10 8.82167Z"
        fill={fill}
      />
    </svg>
  );
};
