import { CustomLoader } from './styles';

const Loader = ({ width, height, fill }) => {
  return (
    <CustomLoader width={width} height={height} fill={fill}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </CustomLoader>
  );
};

export default Loader;
