import {
  ContentState,
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useRef, useState } from 'react';
import {
  ControlButton,
  ControlsWrapper,
  LabelEditor,
  WrapperEditor,
  WrapperInner,
} from './styles';
import { useFormContext } from 'react-hook-form';
import htmlToDraft from 'html-to-draftjs';
import { SEO_METATAGS_EDITOR_TYPES } from 'constants';

const getInitialContentState = (htmlString) => {
  const cBlock = htmlToDraft(htmlString);
  if (cBlock) return ContentState.createFromBlockArray(cBlock.contentBlocks);
  return EditorState.createEmpty();
};
const getInitialEditorState = (htmlString = '') => {
  const contentState = getInitialContentState(htmlString);
  return EditorState.createWithContent(contentState);
};

const EditorComponent = ({ name, label }) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);
  const [editorState, setEditorState] = useState(getInitialEditorState(value));

  const editor = useRef(null);

  const StyleButton = ({ style, onToggle, label }) => {
    let onClickButton = (e) => {
      e.preventDefault();
      onToggle(style);
    };
    return <ControlButton onMouseDown={onClickButton}>{label}</ControlButton>;
  };

  const BlockStyleControls = ({ onToggle }) => {
    return SEO_METATAGS_EDITOR_TYPES.map((type) => (
      <StyleButton
        key={type.label}
        label={type.label}
        onToggle={onToggle}
        style={type.style}
      />
    ));
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  const handleChange = (editorState) => {
    setEditorState(editorState);
    setValue(name, draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const handleClickWrapper = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  useEffect(() => {
    if (value !== draftToHtml(convertToRaw(editorState.getCurrentContent())))
      setEditorState(getInitialEditorState(value));
  }, [value]);

  return (
    <WrapperEditor onClick={handleClickWrapper}>
      <LabelEditor>{label}</LabelEditor>
      <WrapperInner>
        <ControlsWrapper>
          <BlockStyleControls onToggle={onBlockClick} />
        </ControlsWrapper>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={handleChange}
        />
      </WrapperInner>
    </WrapperEditor>
  );
};

export default EditorComponent;
