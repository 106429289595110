export const seoMetatagsInfo = ({ data, isEditMode }) => {
  if (data) {
    return {
      ...(isEditMode && { id: data.id }),
      url: data.url.trim(),
      isIndex: data.isIndex,
      RU: {
        title: data.titleRU,
        description: data.descriptionRU,
        h1: data.h1RU,
        text: data.textRU,
      },
      MD: {
        title: data.titleMD,
        description: data.descriptionMD,
        h1: data.h1MD,
        text: data.textMD,
      },
      EN: {
        title: data.titleEN,
        description: data.descriptionEN,
        h1: data.h1EN,
        text: data.textEN,
      },
    };
  }
};
