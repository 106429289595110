import { useEffect, useState } from 'react';

export const getFilteredSections = ({ propertyType, sections }) => {
  const [filteredSection, setFilteredSection] = useState(sections);

  useEffect(() => {
    switch (propertyType?.value) {
      case 'house':
        setFilteredSection(
          sections?.filter((section) => section?.alias !== 'aboutTheBuilding'),
        );
        break;
      case 'apartments':
        setFilteredSection(
          sections?.filter((section) => section?.alias !== 'aboutArea'),
        );
        break;
      default:
        return setFilteredSection(sections);
    }
  }, [propertyType?.value, sections]);

  return { filteredSection };
};
