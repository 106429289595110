import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon, ConfirmIcon, DropzoneImgIcon } from 'assets';
import Loader from 'components/Loader';
import DropzoneFieldRHF from 'components/react-hook-from/DropzoneRHF';
import TextField from 'components/TextField';
import { sampleTagsInfo } from 'connectors/sampleTagsInfo';
import {
  SUCCESS_MESSAGES,
  ERRORS_MESSAGES,
  PATHS,
  tagsBlocksOptions,
  typePropertyOptions,
  typeOfferOptions,
} from 'constants';
import {
  useAddImage,
  useAddTemplateTags,
  useEditTemplateTags,
  useGetLanguagesQuery,
  useGetTemplateTagsByIdQuery,
} from 'hooks';
import { getSampleTagsData } from 'hooks/getSampleTagsData';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { disableEnterSubmit, nameIsUsedError } from 'utils';
import { addEditSampleTags } from 'validations/schemas/addEditSampleTags';
import {
  ActionButtons,
  AddEditSampleTagsWrapper,
  ButtonText,
  CancelButton,
  ConfirmButton,
  CreateTitle,
  FieldsWrapper,
  LoaderWrapper,
  SampleInnerBlock,
  SampleWrapperBlock,
  TagsBlocksWrapper,
  TitleInner,
  TitleText,
} from './styles';
import Checkbox from 'components/Checkbox';

const AddEditSampleTags = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location?.state?.isEditMode;

  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const {
    data: template,
    isLoading: loadingTemplateTagQuery,
    refetch,
  } = useGetTemplateTagsByIdQuery({
    id: location?.state?.tagId,
  });

  const { ADD_INITIAL_VALUES_SAMPLES_TAGS, EDIT_INITIAL_VALUES_SAMPLES_TAGS } =
    getSampleTagsData({ languages, template });

  const methods = useForm({
    defaultValues: isEditMode
      ? EDIT_INITIAL_VALUES_SAMPLES_TAGS
      : ADD_INITIAL_VALUES_SAMPLES_TAGS,
    resolver: yupResolver(addEditSampleTags()),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const errorFieldsToast = () => {
    Object.keys(errors).length &&
      toast.error(ERRORS_MESSAGES.ERRORS_FIELDS_REQUIRED_NOLANG, {
        position: 'bottom-left',
      });
  };

  const handleResetForm = () => {
    reset(
      isEditMode
        ? EDIT_INITIAL_VALUES_SAMPLES_TAGS
        : ADD_INITIAL_VALUES_SAMPLES_TAGS,
    );
  };

  const { mutateAsync: addImage, isLoading: loadingImage } = useAddImage({
    onError(error) {
      toast.error(error?.response?.data?.message, {
        position: 'bottom-left',
      });
    },
  });

  const { mutateAsync: addTemplatesTags, isLoading: loadingAddTemplateTags } =
    useAddTemplateTags({
      onSuccess() {
        toast.success(SUCCESS_MESSAGES.ADD_TEMPLATE_SUCCESS, {
          position: 'bottom-left',
        });
        handleResetForm();
      },
      onError(error) {
        toast.error(nameIsUsedError(error), {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: editTemplateTags, isLoading: loadingEditTemplateTags } =
    useEditTemplateTags({
      onSuccess() {
        refetch();
        toast.success(SUCCESS_MESSAGES.EDIT_TEMPLATE_SUCCESS, {
          position: 'bottom-left',
        });
        handleResetForm();
      },
      onError(error) {
        toast.error(nameIsUsedError(error), {
          position: 'bottom-left',
        });
      },
    });

  const onSubmit = async (data) => {
    let iconData;

    const isPreviewIcon = data?.icon?.preview.includes(data?.iconToken);

    if (data?.icon && !isPreviewIcon) {
      const formDataIcon = new FormData();
      formDataIcon.append('files', data?.icon);
      const iconDataResponse = await addImage(formDataIcon);
      iconData = iconDataResponse;
    }

    isEditMode
      ? editTemplateTags(
          sampleTagsInfo({ data, languages, isEditMode, iconData }),
        )
      : addTemplatesTags(
          sampleTagsInfo({ data, languages, isEditMode, iconData }),
        );
  };

  useEffect(() => {
    handleResetForm();
  }, [isEditMode, template]);

  useEffect(() => {
    errorFieldsToast();
  }, [errors]);

  const handleCancelTemplateTags = () => {
    return navigate(`${PATHS.samplesTags}`);
  };

  if (loadingTemplateTagQuery || loadingLanguages) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={disableEnterSubmit}
        noValidate
      >
        <AddEditSampleTagsWrapper>
          <CreateTitle>
            <TitleInner addEdit>
              <TitleText>
                {isEditMode ? 'Редактирование шаблона' : 'Создание шаблона'}
              </TitleText>
              <Link to={PATHS.samplesTags}>
                <CloseIcon />
              </Link>
            </TitleInner>
          </CreateTitle>
          <SampleWrapperBlock>
            <SampleInnerBlock>
              <TextField
                name={`templateName`}
                label={'Имя шаблона*'}
                placeholder={'Введите заголовок'}
                type="text"
              />
              <TagsBlocksWrapper isBorderBottom>
                {typeOfferOptions?.map((option) => {
                  return (
                    <Checkbox
                      name={option.value.toLowerCase()}
                      label={option.label}
                      key={option.value}
                    />
                  );
                })}
              </TagsBlocksWrapper>
              <TagsBlocksWrapper isBorderBottom columns={4}>
                {typePropertyOptions?.map((option) => {
                  return (
                    <Checkbox
                      name={option.value}
                      label={option.label}
                      key={option.value}
                    />
                  );
                })}
              </TagsBlocksWrapper>
              <TagsBlocksWrapper>
                {tagsBlocksOptions?.map((option) => {
                  return (
                    <Checkbox
                      name={option.value}
                      label={option.label}
                      key={option.value}
                    />
                  );
                })}
              </TagsBlocksWrapper>
              <DropzoneFieldRHF
                name="icon"
                dropText={'Добавить иконку'}
                imgBg={<DropzoneImgIcon />}
                isSvg
              />
              {languages
                ?.sort((lang) => lang.id)
                ?.map((lang) => {
                  return (
                    <FieldsWrapper key={lang?.id}>
                      <TextField
                        name={`value${lang.iso}`}
                        label={`Значение ${lang.name} *`}
                        placeholder={'Введите значение'}
                        type="text"
                      />
                    </FieldsWrapper>
                  );
                })}
            </SampleInnerBlock>
          </SampleWrapperBlock>
          <ActionButtons>
            <CancelButton
              type="button"
              onClick={handleCancelTemplateTags}
              disabled={
                loadingAddTemplateTags ||
                loadingEditTemplateTags ||
                loadingImage
              }
            >
              <CloseIcon fill={'#9B9EA7'} />
              <ButtonText>Отменить</ButtonText>
            </CancelButton>
            <ConfirmButton
              type="submit"
              disabled={
                loadingAddTemplateTags ||
                loadingEditTemplateTags ||
                loadingImage
              }
            >
              {loadingAddTemplateTags || loadingEditTemplateTags ? (
                <Loader width={'18px'} height={'18px'} fill={'#fff'} />
              ) : (
                <>
                  <ConfirmIcon />
                  <ButtonText active>
                    {isEditMode ? 'Сохранить' : 'Добавить'}
                  </ButtonText>
                </>
              )}
            </ConfirmButton>
          </ActionButtons>
        </AddEditSampleTagsWrapper>
      </form>
    </FormProvider>
  );
};

export default AddEditSampleTags;
