export const MessengerIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0011 1.6875C2.42059 1.6875 -1.8793 13.0128 5.58339 18.657V22.3124L9.06845 20.3788C15.615 22.2111 22.2076 17.6211 22.2076 11.2312C22.2122 5.95983 17.6406 1.6875 12.0011 1.6875Z"
        fill="#448AFF"
      />
      <path
        d="M13.0784 14.4906L10.4358 11.7468L5.35327 14.5596L10.9285 8.6438L13.571 11.3877L18.6534 8.57471L13.0784 14.4906Z"
        fill="white"
      />
    </svg>
  );
};
