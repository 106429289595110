export const sampleCustomFieldsInfo = ({ data, languages, isEditMode }) => {
  if (data) {
    return isEditMode
      ? {
          id: data.id,
          name: data.templateName,
          sectionId: +data.sectionName?.value,
          fields: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                key: data[`key${lang?.iso}`],
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
        }
      : {
          name: data.templateName,
          sectionId: +data.sectionName?.value,
          fields: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                key: data[`key${lang?.iso}`],
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
        };
  }
};
