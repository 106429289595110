import { CloseIcon, ConfirmIcon } from 'assets';
import { announcementInfo } from 'connectors/announcementInfo';
import { SUCCESS_MESSAGES, ERRORS_MESSAGES, PATHS } from 'constants';
import {
  getAnnouncementsFieldsData,
  useAddAnnouncement,
  useAddImage,
  useGetAddressQuery,
  useGetAllAgentsQuery,
  useGetAnnouncementByIdQuery,
  useGetLanguagesQuery,
  useGetSectionQuery,
} from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { disableEnterSubmit, resetFieldValuesAnnouncements } from 'utils';
import {
  ActionButtons,
  AddAnnouncementWrapper,
  ButtonText,
  CancelButton,
  ConfirmButton,
  CreateTitle,
  LanguageItem,
  LanguageTabsWrapper,
  LoaderWrapper,
  TabsList,
  TitleInner,
  TitleText,
} from './styles';
import Tab from 'components/Tabs/Tab';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEditAnnouncement } from 'hooks/mutations/useEditAnnouncement';
import { addEditAnnouncementSchema } from 'validations/schemas/addEditAnnouncementSchema';

const AddEditAnnouncement = () => {
  const { data: sections, isLoading: loadingSections } = useGetSectionQuery();
  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const { data: agents, isLoading: loadingAgents } = useGetAllAgentsQuery();

  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location?.state?.isEditMode;

  const {
    data: announcement,
    isLoading: loadingAnnouncementQuery,
    refetch,
  } = useGetAnnouncementByIdQuery({
    id: location?.state?.announcementId,
  });

  const { initValuesFull, editValuesFull } = useMemo(
    () =>
      getAnnouncementsFieldsData({
        languages,
        sections,
        announcement,
        isEditMode,
        agents: agents?.rows,
      }),
    [[languages, sections, announcement, isEditMode, agents?.rows]],
  );

  const methods = useForm({
    defaultValues: isEditMode ? editValuesFull : initValuesFull,
    resolver: yupResolver(addEditAnnouncementSchema()),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const isCityChisinau = watch('city')?.value === 'chisinau';

  const { data: address, isLoading: loadingAdress } = useGetAddressQuery({
    enabled: isCityChisinau,
  });

  const errorFieldsToast = () => {
    Object.keys(errors).length &&
      toast.error(ERRORS_MESSAGES.ERRORS_FIELDS_REQUIRED, {
        position: 'bottom-left',
      });
  };

  const [tab, setTab] = useState('RU');

  const handleChangeTab = (tab) => {
    setTab(tab);
  };

  const { mutateAsync: addImage, isLoading: loadingImage } = useAddImage({
    onError(error) {
      toast.error(error?.response?.data?.message, {
        position: 'bottom-left',
      });
    },
  });

  const { mutateAsync: addAnnouncement, isLoading: loadingAnnouncement } =
    useAddAnnouncement({
      onSuccess() {
        toast.success(SUCCESS_MESSAGES.ADD_ANNOUNCEMENT_SUCCESS, {
          position: 'bottom-left',
        });
      },
      onError(error) {
        toast.error(error?.response?.data?.error, {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: editAnnouncement } = useEditAnnouncement({
    onSuccess() {
      refetch();
      toast.success(SUCCESS_MESSAGES.EDIT_ANNOUNCEMENT_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.error, {
        position: 'bottom-left',
      });
    },
  });

  const onSubmit = async (data) => {
    let mapImgData;
    let imagesData;
    let videoData;
    let seoImgData;

    const imagesLocal = data?.photos.map((item) => {
      delete item.preview;
      return item;
    });
    const imagesServer = data?.photosTokens;

    const isPreviewMapImg = data.mapimg?.preview.includes(data?.mapimgToken);
    const isPreviewSeoImg = data.seoimg.preview.includes(data?.seoimgToken);
    const isPreviewVideo = data?.video?.preview?.includes(data?.videoToken);

    if (!isPreviewMapImg) {
      const formDataMap = new FormData();
      formDataMap.append('files', data.mapimg);
      const mapImgResponse = await addImage(formDataMap);
      mapImgData = mapImgResponse;
    }

    if (!isPreviewSeoImg) {
      const formDataSeo = new FormData();
      formDataSeo.append('files', data.seoimg);
      const seoImgResponse = await addImage(formDataSeo);
      seoImgData = seoImgResponse;
    }

    if (!(JSON.stringify(imagesLocal) === JSON.stringify(imagesServer))) {
      const formDataImages = new FormData();

      data.photos
        ?.map((item, index) => {
          if (item?.order >= 0) {
            item.order = index + 1;
            return JSON.stringify(item);
          }
          return item;
        })
        .forEach((item) => {
          formDataImages.append('files', item);
        });
      const imagesResponse = await addImage(formDataImages);
      imagesData = imagesResponse;
    }

    if (!isPreviewVideo && !data.isAffiliateAnnouncement) {
      const formDataVideo = new FormData();
      formDataVideo.append('files', data?.video);
      const videoResponse = await addImage(formDataVideo);
      videoData = videoResponse;
    }

    isEditMode
      ? editAnnouncement(
          announcementInfo({
            data,
            mapImgData,
            imagesData,
            videoData,
            seoImgData,
            isEditMode,
          }),
        )
      : addAnnouncement(
          announcementInfo({
            data,
            mapImgData,
            imagesData,
            seoImgData,
            videoData,
          }),
        );

    resetFieldValuesAnnouncements({
      reset,
      isEditMode,
      editValuesFull,
      initValuesFull,
    });
  };

  useEffect(() => {
    resetFieldValuesAnnouncements({
      reset,
      isEditMode,
      editValuesFull,
      initValuesFull,
    });
  }, [sections, languages, isEditMode, announcement]);

  useEffect(() => {
    errorFieldsToast();
  }, [errors]);

  const handleCancelAnnouncement = () => {
    return navigate(`${PATHS.home}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (
    loadingLanguages ||
    loadingSections ||
    loadingAnnouncementQuery ||
    loadingAgents ||
    loadingAdress
  ) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={disableEnterSubmit}
        noValidate
      >
        <AddAnnouncementWrapper>
          <CreateTitle>
            <TitleInner>
              <TitleText>
                {isEditMode
                  ? 'Редактирование объявления'
                  : 'Создание объявления'}
              </TitleText>
              <Link to={PATHS.home}>
                <CloseIcon />
              </Link>
            </TitleInner>
          </CreateTitle>
          <LanguageTabsWrapper>
            {languages?.map((lang, index) => {
              return (
                <LanguageItem
                  key={index}
                  active={tab === lang?.iso}
                  onClick={() => handleChangeTab(lang?.iso)}
                >
                  {lang.iso}
                </LanguageItem>
              );
            })}
          </LanguageTabsWrapper>
          <TabsList>
            {languages?.map((lang) => {
              return (
                tab === lang?.iso && (
                  <Tab
                    key={lang.id}
                    sections={sections}
                    lang={lang?.iso}
                    langId={lang?.id}
                    agents={agents?.rows}
                    address={address}
                    languages={languages}
                  />
                )
              );
            })}
          </TabsList>
          <ActionButtons>
            <CancelButton
              type="button"
              onClick={handleCancelAnnouncement}
              disabled={loadingImage || loadingAnnouncement}
            >
              <CloseIcon fill={'#9B9EA7'} />
              <ButtonText>Отменить</ButtonText>
            </CancelButton>
            <ConfirmButton
              type="submit"
              disabled={loadingImage || loadingAnnouncement}
            >
              {loadingImage || loadingAnnouncement ? (
                <Loader width={'18px'} height={'18px'} fill={'#fff'} />
              ) : (
                <>
                  <ConfirmIcon />
                  <ButtonText active>
                    {isEditMode ? 'Сохранить' : 'Разместить'}
                  </ButtonText>
                </>
              )}
            </ConfirmButton>
          </ActionButtons>
        </AddAnnouncementWrapper>
      </form>
    </FormProvider>
  );
};

export default AddEditAnnouncement;
