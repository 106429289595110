import * as yup from 'yup';

export const addEditSeoMetatagsSchema = () => {
  return yup.object().shape({
    url: yup.string().required(),
    titleRU: yup.string(),
    titleMD: yup.string(),
    titleEN: yup.string(),
    descriptionRU: yup.string(),
    descriptionMD: yup.string(),
    descriptionEN: yup.string(),
    h1RU: yup.string(),
    h1MD: yup.string(),
    h1EN: yup.string(),
  });
};
