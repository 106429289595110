import Loader from 'components/Loader';
import { tableHeadViewsItems } from 'constants';
import { useGetStatisticQuery } from 'hooks/queries/useGetStatisticQuery';
import { useStatisticPagination } from 'hooks/useStatisticPagination';
import { LoaderWrapper } from 'pages/AddEditAnnouncement/styles';
import { useEffect } from 'react';

import {
  AnnouncementsViewListTable,
  ClicksPhoneNumberCell,
  ClicksSocialMediaCell,
  IdCell,
  ListTitle,
  NotFound,
  TableBody,
  TableHead,
  TableRow,
  TableWrapper,
  TitleInner,
  TitleText,
  UniqueViewsCell,
  Views3dTourCell,
  ViewsCell,
  ViewsVideoFullCell,
  WrapperLoader,
} from './styles';

const Views = () => {
  const {
    data: statistic,
    isLoading: loadingStatistic,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetStatisticQuery();

  const { statisticCurrentData } = useStatisticPagination({
    statistic,
    fetchNextPage,
    isRefetching,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingStatistic) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <ListTitle>
        <TitleInner>
          <TitleText>Просмотры объявлений</TitleText>
        </TitleInner>
      </ListTitle>
      {statisticCurrentData?.length ? (
        <TableWrapper>
          <AnnouncementsViewListTable>
            <TableBody>
              <TableHead>
                {tableHeadViewsItems.map((item) => {
                  return <td key={item.title}>{item.title}</td>;
                })}
              </TableHead>
              {statisticCurrentData?.map((announcement) => {
                return (
                  <TableRow key={announcement.id}>
                    <IdCell>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/announcement/${announcement.announcementId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {announcement.announcementId}
                      </a>
                    </IdCell>
                    <UniqueViewsCell>
                      {announcement.uniqueViewCounter}
                    </UniqueViewsCell>
                    <ViewsCell>{announcement.numberOfView}</ViewsCell>
                    <ClicksPhoneNumberCell>
                      {announcement.numberOfPhoneClick}
                    </ClicksPhoneNumberCell>
                    <ClicksSocialMediaCell>
                      {announcement.numberOfMessengerClick}
                    </ClicksSocialMediaCell>
                    <Views3dTourCell>
                      {announcement.numberOfView3dTour}
                    </Views3dTourCell>
                    <ViewsVideoFullCell>
                      {announcement.numberOfViewVideoToTheEnd}
                    </ViewsVideoFullCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </AnnouncementsViewListTable>
          {isFetching && (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </TableWrapper>
      ) : (
        <NotFound>{'Информация о просмотрах не найдена'}</NotFound>
      )}
    </>
  );
};
export default Views;
