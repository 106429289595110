import { AddIcon, CloseIcon } from 'assets';
import Loader from 'components/Loader';
import { useGetLanguagesQuery } from 'hooks';
import { useState } from 'react';
import Modal from 'react-modal';
import {
  AddMoreButton,
  AddText,
  CheckboxWrapper,
  CloseWrapper,
  CustomCheckbox,
  Label,
  LoaderWrapper,
  modalCustomStyles,
  ModalTitle,
  ModalWrapper,
  TemplatesWrapper,
} from './styles';
import { useGetAllTemplateTags } from 'hooks/queries/useGetAllTemplateTags';
import { useModalContext } from 'context/modal/modal.provider';

const SamplesTagsModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const { modalData, formValue } = infoModal.content;
  const isOpen = infoModal.open;

  const { getValues, setValue } = formValue;

  const [activeIds, setActiveIds] = useState([]);

  const handleClose = () => {
    setActiveIds([]);
    onCloseModal();
  };

  const { data: templates, isLoading: loadingTemplatesTags } =
    useGetAllTemplateTags();

  const templatesCurrentData = templates?.rows;

  const templatesCurrentFilteredData = templatesCurrentData?.filter(
    (tag) =>
      !modalData?.currentTemplateIds?.includes(tag?.id) &&
      tag?.types?.includes(modalData.templateName) &&
      tag?.propertyTypes?.includes(modalData.propertyType) &&
      tag?.announcementTypes?.includes(
        modalData.announcementType.toLowerCase(),
      ),
  );

  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const onCheckBoxChange = ({ event, id }) => {
    if (event.target.checked) {
      setActiveIds((prev) => [...prev, id]);
    } else {
      setActiveIds((prev) => {
        const newValue = [...prev];
        let index = newValue.findIndex((item) => item === id);
        newValue.splice(index, 1);
        return newValue;
      });
    }
  };

  const handleAddTemplatesTags = () => {
    languages?.map((lang) => {
      const formValue = getValues(`${modalData.templateName}${lang?.iso}`);
      formValue.push(
        templatesCurrentFilteredData
          ?.filter((data) => activeIds.includes(data?.id))
          .map((item) => {
            const currentItem = item?.tags[0].find(
              (item) => item?.languageId === lang?.id,
            );
            return {
              languageId: currentItem.languageId,
              groupName: modalData.inputName,
              name: currentItem.value,
              iconToken: item.iconToken,
              tagTemplateId: item.id,
            };
          }),
      );
      setValue(`${modalData.templateName}${lang?.iso}`, formValue.flat());
    });

    handleClose();
  };

  if (loadingTemplatesTags || loadingLanguages) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={modalCustomStyles}
      ariaHideApp={false}
      contentLabel="Samples tags modal"
    >
      <ModalWrapper>
        <CloseWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseWrapper>
        <ModalTitle>Выберите шаблоны из списка</ModalTitle>
        <TemplatesWrapper>
          {templatesCurrentFilteredData?.length
            ? templatesCurrentFilteredData?.map((template, index) => {
                return (
                  <CheckboxWrapper key={index}>
                    <Label>
                      <CustomCheckbox
                        checked={activeIds.includes(template?.id)}
                        type="checkbox"
                        onChange={(e) =>
                          onCheckBoxChange({
                            event: e,
                            id: template?.id,
                          })
                        }
                      />
                      {template?.iconToken && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/uploads/images/${template?.iconToken}`}
                          alt={template?.name}
                        />
                      )}
                      {template?.name}
                    </Label>
                  </CheckboxWrapper>
                );
              })
            : 'Шаблоны не найдены'}
        </TemplatesWrapper>
        <AddMoreButton type="button" onClick={handleAddTemplatesTags}>
          <AddIcon fill={'#fff'} />
          <AddText>Добавить</AddText>
        </AddMoreButton>
      </ModalWrapper>
    </Modal>
  );
};

export default SamplesTagsModal;
