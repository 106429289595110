import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getFieldName } from 'utils';
import { Label, PhoneInputWrapper } from './styles';

const CustomPhoneInput = ({ label = 'Номер телефона', name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <PhoneInputWrapper hasError={hasError}>
          {label && <Label>{label}</Label>}
          <PhoneInput
            {...field}
            inputExtraProps={{
              ref,
              autoFocus: true,
            }}
            country={'md'}
            regions={'europe'}
            placeholder={'Введите номер телефона'}
          />
        </PhoneInputWrapper>
      )}
    />
  );
};

export default CustomPhoneInput;
