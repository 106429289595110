export const WhatsAppIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5517 4.35206C17.535 2.33062 14.8494 1.21875 11.9952 1.21875C6.10404 1.21875 1.31023 6.01261 1.31023 11.9037C1.31023 13.7855 1.8012 15.6241 2.73495 17.2462L1.21887 22.7812L6.88381 21.2939C8.44325 22.1458 10.1999 22.5935 11.9904 22.5935H11.9952C17.8816 22.5935 22.7812 17.7996 22.7812 11.9085C22.7812 9.05437 21.5684 6.37355 19.5517 4.35206Z"
        fill="white"
      />
      <path
        d="M11.9952 20.7934C10.3973 20.7934 8.83302 20.365 7.47092 19.5564L7.14847 19.3639L3.78894 20.2447L4.68421 16.967L4.47242 16.6301C3.58203 15.215 3.11511 13.5834 3.11511 11.9037C3.11511 7.00881 7.10033 3.02368 12 3.02368C14.3728 3.02368 16.6012 3.94778 18.2762 5.62749C19.9511 7.30726 20.9811 9.53575 20.9764 11.9086C20.9764 16.8083 16.89 20.7934 11.9952 20.7934Z"
        fill="#2CB742"
      />
      <path
        d="M16.866 14.1417C16.6012 14.0071 15.2873 13.3619 15.0419 13.2754C14.7964 13.1839 14.6183 13.1407 14.4403 13.4102C14.2622 13.6797 13.752 14.2764 13.5931 14.4594C13.4391 14.6375 13.2802 14.6616 13.0155 14.5268C11.4465 13.7423 10.4166 13.1263 9.38175 11.3502C9.10739 10.8785 9.65615 10.9122 10.1662 9.89189C10.253 9.71372 10.2097 9.55974 10.1422 9.42497C10.0748 9.29021 9.54056 7.97625 9.31917 7.44197C9.10261 6.92218 8.88122 6.99436 8.71753 6.98475C8.56354 6.9751 8.38537 6.9751 8.20739 6.9751C8.02926 6.9751 7.74051 7.0425 7.49503 7.30721C7.24959 7.57674 6.56128 8.22174 6.56128 9.53569C6.56128 10.8496 7.51903 12.1203 7.64901 12.2984C7.78373 12.4764 9.53095 15.1718 12.2117 16.3317C13.906 17.0633 14.5702 17.1258 15.4173 17.0007C15.9322 16.9237 16.996 16.3557 17.2174 15.73C17.4388 15.1044 17.4388 14.5702 17.3714 14.4595C17.3088 14.339 17.1307 14.2717 16.866 14.1417Z"
        fill="white"
      />
    </svg>
  );
};
