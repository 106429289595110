export const PhotoDropIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10_11)">
        <path
          d="M17.5 12.5V15H20V16.6667H17.5V19.1667H15.8333V16.6667H13.3333V15H15.8333V12.5H17.5ZM17.5067 2.5C17.9633 2.5 18.3333 2.87083 18.3333 3.3275V10.8333H16.6667V4.16667H3.33333V15.8325L11.6667 7.5L14.1667 10V12.3575L11.6667 9.8575L5.68917 15.8333H11.6667V17.5H2.49333C2.27401 17.4998 2.06375 17.4125 1.90874 17.2573C1.75373 17.1022 1.66667 16.8918 1.66667 16.6725V3.3275C1.66819 3.10865 1.75575 2.89918 1.91043 2.74435C2.0651 2.58951 2.27448 2.50175 2.49333 2.5H17.5067ZM6.66667 5.83333C7.10869 5.83333 7.53262 6.00893 7.84518 6.32149C8.15774 6.63405 8.33333 7.05797 8.33333 7.5C8.33333 7.94203 8.15774 8.36595 7.84518 8.67851C7.53262 8.99107 7.10869 9.16667 6.66667 9.16667C6.22464 9.16667 5.80072 8.99107 5.48815 8.67851C5.17559 8.36595 5 7.94203 5 7.5C5 7.05797 5.17559 6.63405 5.48815 6.32149C5.80072 6.00893 6.22464 5.83333 6.66667 5.83333V5.83333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_11">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
