import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { disableEnterSubmit } from 'utils';
import {
  ActionButtons,
  AddSeoMetatagsCreateTitle,
  AddSeoMetatagsTitleInner,
  AddSeoMetatagsTitleText,
  AddSeoMetatagsWrapper,
  ButtonText,
  CancelButton,
  ConfirmButton,
  LanguageItem,
  LanguageTabsWrapper,
  LoaderWrapper,
  TabsList,
} from './styles';
import { PATHS, ERRORS_MESSAGES, SUCCESS_MESSAGES } from 'constants';
import { CloseIcon, ConfirmIcon } from 'assets';
import {
  useAddSeoMetatags,
  useEditSeoMetatags,
  useGetLanguagesQuery,
  useGetSeoMetadataByIdQuery,
} from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import SeoMetatagsTab from 'components/Tabs/SeoMetatags';
import { getSeoMetatagsFieldsData } from 'hooks/getSeoMetatagsFieldsData';
import { seoMetatagsInfo } from 'connectors/seoMetatagsInfo';
import { addEditSeoMetatagsSchema } from 'validations/schemas';
import { metaTagsUrlExistError } from 'utils/metaTagsUrlExistError';

const AddEditSeoMetatags = () => {
  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location?.state?.isEditMode;

  const {
    data: metaTag,
    isLoading: loadingMetaTag,
    refetch,
  } = useGetSeoMetadataByIdQuery({
    id: location?.state?.metatagId,
  });

  const { initValuesFull, editValuesFull } = useMemo(
    () =>
      getSeoMetatagsFieldsData({
        languages,
        metaTag: metaTag,
      }),
    [[languages, metaTag]],
  );

  const methods = useForm({
    defaultValues: isEditMode ? editValuesFull : initValuesFull,
    resolver: yupResolver(addEditSeoMetatagsSchema()),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const errorFieldsToast = () => {
    Object.keys(errors).length &&
      toast.error(ERRORS_MESSAGES.ERRORS_FIELDS_REQUIRED, {
        position: 'bottom-left',
      });
  };

  const [tab, setTab] = useState('RU');

  const handleChangeTab = (tab) => {
    setTab(tab);
  };

  const { mutateAsync: addSeoMetatags, isLoading: loadingaddSeoMetatags } =
    useAddSeoMetatags({
      onSuccess() {
        toast.success(SUCCESS_MESSAGES.ADD_METATAGS_SUCCESS, {
          position: 'bottom-left',
        });
      },
      onError(error) {
        toast.error(metaTagsUrlExistError(error), {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: editSeoMetatags } = useEditSeoMetatags({
    onSuccess() {
      refetch();
      toast.success(SUCCESS_MESSAGES.EDIT_METATAGS_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(metaTagsUrlExistError(error), {
        position: 'bottom-left',
      });
    },
  });

  const resetFieldValuesSeoMetatags = ({
    reset,
    isEditMode,
    editValuesFull,
    initValuesFull,
  }) => {
    return reset(isEditMode ? editValuesFull : initValuesFull);
  };

  const onSubmit = (data) => {
    isEditMode
      ? editSeoMetatags(seoMetatagsInfo({ data, isEditMode }))
      : addSeoMetatags(seoMetatagsInfo({ data }));

    resetFieldValuesSeoMetatags({
      reset,
      isEditMode,
      editValuesFull,
      initValuesFull,
    });
  };

  useEffect(() => {
    resetFieldValuesSeoMetatags({
      reset,
      isEditMode,
      editValuesFull,
      initValuesFull,
    });
  }, [languages, isEditMode, metaTag]);

  useEffect(() => {
    errorFieldsToast();
  }, [errors]);

  const handleCancelSeoMetatags = () => {
    return navigate(`${PATHS.seo}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingLanguages || loadingMetaTag) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={disableEnterSubmit}
        noValidate
      >
        <AddSeoMetatagsWrapper>
          <AddSeoMetatagsCreateTitle>
            <AddSeoMetatagsTitleInner>
              <AddSeoMetatagsTitleText>
                {isEditMode
                  ? 'Редактирование cео для страницы'
                  : 'Создание сео для страницы'}
              </AddSeoMetatagsTitleText>
              <Link to={PATHS.seo}>
                <CloseIcon />
              </Link>
            </AddSeoMetatagsTitleInner>
          </AddSeoMetatagsCreateTitle>
          <LanguageTabsWrapper>
            {languages?.map((lang, index) => {
              return (
                <LanguageItem
                  key={index}
                  active={tab === lang?.iso}
                  onClick={() => handleChangeTab(lang?.iso)}
                >
                  {lang.iso}
                </LanguageItem>
              );
            })}
          </LanguageTabsWrapper>
          <TabsList>
            {languages?.map((lang) => {
              return (
                tab === lang?.iso && (
                  <SeoMetatagsTab key={lang.id} lang={lang?.iso} />
                )
              );
            })}
          </TabsList>
          <ActionButtons>
            <CancelButton
              type="button"
              onClick={handleCancelSeoMetatags}
              disabled={loadingaddSeoMetatags}
            >
              <CloseIcon fill={'#9B9EA7'} />
              <ButtonText>Отменить</ButtonText>
            </CancelButton>
            <ConfirmButton type="submit" disabled={loadingaddSeoMetatags}>
              {loadingaddSeoMetatags ? (
                <Loader width={'18px'} height={'18px'} fill={'#fff'} />
              ) : (
                <>
                  <ConfirmIcon />
                  <ButtonText active>
                    {isEditMode ? 'Сохранить' : 'Разместить'}
                  </ButtonText>
                </>
              )}
            </ConfirmButton>
          </ActionButtons>
        </AddSeoMetatagsWrapper>
      </form>
    </FormProvider>
  );
};

export default AddEditSeoMetatags;
