export const agentInfo = ({ data, agentPhotoData, isEditMode }) => {
  if (data) {
    return isEditMode
      ? {
          id: data.id,
          personName: data.agentname,
          agentName: data.agencyname,
          phone: data.phoneNumber,
          image: agentPhotoData?.[0]?.token || data.agentToken,
          contactInWhatsapp: data.whatsapp,
          contactInViber: data.viber,
          contactInMessenger: data.messenger,
          contactInTelegram: data.telegram,
          documentsConfirmed: true,
        }
      : {
          personName: data.agentname,
          agentName: data.agencyname,
          phone: data.phoneNumber,
          image: agentPhotoData[0]?.token,
          contactInWhatsapp: data.whatsapp,
          contactInViber: data.viber,
          contactInMessenger: data.messenger,
          contactInTelegram: data.telegram,
          documentsConfirmed: true,
        };
  }
};
