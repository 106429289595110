import { getTemplateTagsById } from 'api';
import { useQuery } from 'react-query';

export const useGetTemplateTagsByIdQuery = (options) => {
  return useQuery(
    ['templateTagsById', options.id],
    () => getTemplateTagsById(options),
    {
      refetchOnWindowFocus: false,
    },
  );
};
