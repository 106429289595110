import * as yup from 'yup';

export const addEditAgentSchema = () => {
  return yup.object().shape({
    agentname: yup.string().required(),
    agencyname: yup.string().required(),
    phoneNumber: yup.string().required(),
    agent: yup.mixed().required(),
  });
};
