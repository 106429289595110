export const HeatingIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.3333C9.32608 15.3333 10.5979 14.8065 11.5355 13.8689C12.4732 12.9312 13 11.6594 13 10.3333C13 9.756 12.8467 9.202 12.6667 8.68667C11.5553 9.78467 10.7113 10.3333 10.1333 10.3333C12.7967 5.66667 11.3333 3.66667 7.33333 1C7.66667 4.33333 5.46933 5.84933 4.57467 6.69133C3.84488 7.37779 3.33816 8.26765 3.12019 9.24555C2.90222 10.2235 2.98306 11.2443 3.35223 12.1757C3.7214 13.1071 4.36187 13.9061 5.1906 14.4691C6.01934 15.0322 6.9981 15.3332 8 15.3333V15.3333ZM8.47333 3.49C10.634 5.32333 10.6447 6.748 8.97533 9.67267C8.468 10.5613 9.11 11.6667 10.1333 11.6667C10.592 11.6667 11.056 11.5333 11.546 11.27C11.4008 11.8187 11.1301 12.3261 10.7554 12.7524C10.3807 13.1787 9.9122 13.5122 9.38671 13.7266C8.86123 13.941 8.29313 14.0305 7.72717 13.9881C7.16121 13.9456 6.61283 13.7724 6.1252 13.4819C5.63758 13.1915 5.22403 12.7919 4.91712 12.3145C4.61021 11.8371 4.41832 11.2949 4.35655 10.7307C4.29477 10.1665 4.36482 9.59573 4.56116 9.06322C4.75749 8.53071 5.07477 8.05105 5.488 7.662C5.572 7.58333 5.998 7.20533 6.01667 7.18867C6.29933 6.93533 6.532 6.71067 6.762 6.46467C7.582 5.586 8.17133 4.61133 8.47267 3.49H8.47333Z"
        fill="#128555"
      />
    </svg>
  );
};
