import { AddIcon } from 'assets';
import { IsFavoriteIcon } from 'assets/IsFavoriteIcon';
import Loader from 'components/Loader';
import {
  SUCCESS_MESSAGES,
  BREACKPOINTS,
  tableHeadItems,
  PATHS,
} from 'constants';
import { useAnnouncementsPagination, useDeleteAnnouncement } from 'hooks';
import { useGetAnnouncementsQuery } from 'hooks/queries/useGetAnnouncementsQuery';
import { LoaderWrapper } from 'pages/AddEditAnnouncement/styles';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAnnouncementTypeLabel,
  getMainImage,
  getProductNumberFormat,
} from 'utils';

import {
  ActionsCell,
  AddNewBlock,
  AddNewButton,
  AddText,
  AnnouncementTypeCell,
  AnnouncementsListTable,
  AreaTotalCell,
  DeleteButton,
  EditButton,
  FavoriteCell,
  IdCell,
  ListTitle,
  MainImgCell,
  NotFound,
  PriceCell,
  TableBody,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
  TitleInner,
  TitleText,
  WrapperLoader,
} from './styles';

const Home = () => {
  const navigate = useNavigate();

  const {
    data: announcements,
    isLoading: loadingAnnouncements,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetAnnouncementsQuery();

  const { annoucementsRU } = useAnnouncementsPagination({
    announcements,
    fetchNextPage,
    isRefetching,
  });

  const { mutate: deleteAnnouncement } = useDeleteAnnouncement({
    onSuccess() {
      navigate(0); // bad decision refetch, needs to be fixed in the future
      toast.success(SUCCESS_MESSAGES.DELETE_ANNOUNCEMENT_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.error, {
        position: 'bottom-left',
      });
    },
  });

  const handleEditAnnoucements = (id) => {
    navigate(`${BREACKPOINTS.editAnnouncement}/${id}`, {
      state: { isEditMode: true, announcementId: id },
    });
  };

  const handleDeleteAnnoucements = (id) => {
    deleteAnnouncement({
      id,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingAnnouncements) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <ListTitle>
        <TitleInner>
          <TitleText>Список объявлений</TitleText>
        </TitleInner>
      </ListTitle>
      <AddNewBlock>
        <Link to={PATHS.addAnnouncement}>
          <AddNewButton type="button">
            <AddIcon fill={'#fff'} />
            <AddText>Создать объявление</AddText>
          </AddNewButton>
        </Link>
      </AddNewBlock>
      {annoucementsRU?.length ? (
        <TableWrapper>
          <AnnouncementsListTable>
            <TableBody>
              <TableHead>
                {tableHeadItems.map((item) => {
                  return <td key={item.title}>{item.title}</td>;
                })}
              </TableHead>
              {annoucementsRU?.map((announcement) => {
                return (
                  <TableRow key={announcement.id}>
                    <IdCell>
                      <a
                        href={`${process.env.REACT_APP_API_URL}/announcement/${announcement.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {announcement.id}
                      </a>
                    </IdCell>
                    <AnnouncementTypeCell>
                      {getAnnouncementTypeLabel(announcement)}
                    </AnnouncementTypeCell>
                    <FavoriteCell>
                      {announcement.isFavorite && <IsFavoriteIcon />}
                    </FavoriteCell>
                    <MainImgCell>
                      <img
                        src={`${
                          process.env.REACT_APP_API_URL
                        }/uploads/images/${getMainImage({
                          images: announcement.images,
                        })}`}
                        alt={announcement.images[0]?.name}
                      />
                    </MainImgCell>
                    <TitleCell>{announcement.announcementInfo.title}</TitleCell>
                    <PriceCell>
                      {getProductNumberFormat(announcement.price)}
                    </PriceCell>
                    <AreaTotalCell>
                      {getProductNumberFormat(announcement.areaTotal)}
                    </AreaTotalCell>

                    <ActionsCell>
                      <EditButton
                        type="button"
                        onClick={() => handleEditAnnoucements(announcement.id)}
                      >
                        Редактировать
                      </EditButton>
                      <DeleteButton
                        type="button"
                        onClick={() =>
                          handleDeleteAnnoucements(announcement.id)
                        }
                      >
                        Удалить
                      </DeleteButton>
                    </ActionsCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </AnnouncementsListTable>
          {isFetching && (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </TableWrapper>
      ) : (
        <NotFound>{'Объявления не найдены'}</NotFound>
      )}
    </>
  );
};
export default Home;
