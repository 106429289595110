import { useEffect, useState } from 'react';

export const useTemplatesPagination = ({
  templates,
  fetchNextPage,
  modalIsOpen,
  isTemplatesPage,
  limit,
}) => {
  const [offset, setOffset] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const paginatedTemplatesData = () => {
    let result = [];
    templates?.pages?.forEach((item) => {
      result.push(...item.rows);
    });
    return result;
  };

  const templatesCurrentData = paginatedTemplatesData();

  const isMoreTemplates =
    templates?.pages?.[0]?.count > templatesCurrentData?.length;

  const scrollHandler = (e) => {
    if (
      modalIsOpen &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <
        10 &&
      isMoreTemplates
    ) {
      setIsDataLoading(true);
    }
    if (
      isTemplatesPage &&
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      isMoreTemplates
    ) {
      setIsDataLoading(true);
    }
  };

  useEffect(() => {
    if (isDataLoading && isMoreTemplates) {
      setOffset((prev) => {
        return prev + limit;
      });
    }
  }, [isDataLoading]);

  useEffect(() => {
    offset > 0 &&
      fetchNextPage({
        pageParam: {
          limit,
          offset: offset,
        },
      }).then(() => {
        setIsDataLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    if (isMoreTemplates && (modalIsOpen || isTemplatesPage)) {
      document.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [templates?.pages?.[0]?.count, templatesCurrentData, isMoreTemplates]);

  return { templatesCurrentData, scrollHandler };
};
