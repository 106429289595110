import styled from 'styled-components';

export const AddAnnouncementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 26px;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const CreateTitle = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background: #fff;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 2px;
  box-shadow: 0px 2px 10px rgba(21, 21, 21, 0.04);
`;

export const TitleInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 810px;
  background: #fff;
`;

export const TitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`;

export const LanguageTabsWrapper = styled.div`
  display: flex;
  background: #fff;
  height: 52px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-bottom: 1px solid #eef2fa;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    border-top: 1px solid #eef2fa;
  }
`;

export const LanguageItem = styled.div`
  display: flex;
  width: 100%;
  max-width: 270px;
  height: 52px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #161e41;
  border-bottom: ${(props) => (props.active ? '2px solid #161E41' : 'none')};
  cursor: pointer;
`;

export const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 810px;
  justify-content: flex-end;
  margin-top: 16px;

  @media (max-width: 816px) {
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
    background: #fff;
    height: 92px;
    padding: 16px 16px 0 16px;
    justify-content: space-between;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 164px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: #d8ddf1;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0;
  :hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 42px;
    max-width: initial;
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 164px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: #161e41;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 30px;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    height: 42px;
    max-width: initial;
  }
`;

export const ButtonText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${(props) => (props.active ? '#ffffff' : '#9b9ea7')};
  margin-left: 6px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
