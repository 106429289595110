export const API_URL = process.env.REACT_APP_API_URL;

export const BREACKPOINTS = {
  languages: '/language',
  imgUpload: '/image/upload',
  announcementCreate: '/announcement/create',
  announcement: '/announcement',
  editAnnouncement: '/edit-announcement',
  addAgent: '/add-agent',
  editAgent: '/edit-agent',
  section: '/section',
  agent: '/agent',
  address: '/address',
  customField: '/template/field',
  templateTag: '/template/tag',
  statistic: '/statistic',
  seoMetatags: '/seo-metatag',
};

export const PATHS = {
  home: '/',
  agents: '/agents',
  addAgent: '/add-agent',
  editAgent: '/edit-agent/:agentId',
  addAnnouncement: '/add-announcement',
  editAnnouncement: '/edit-announcement/:announcementId',
  samplesCustomFields: '/samples-customfields',
  addCustomField: '/add-customfield',
  editCustomField: '/edit-customfield',
  editCustomFieldId: '/edit-customfield/:customFieldId',
  samplesTags: '/samples-tags',
  addTag: '/add-tag',
  editTag: '/edit-tag',
  editTagId: '/edit-tag/:tagId',
  views: '/views',
  seo: '/seo',
  addSeoMetatags: '/add-metatags',
  editSeoMetatags: '/edit-metatags',
  editSeoMetatagsId: '/edit-metatags/:metatagId',
};

export const navigationLinks = [
  { name: 'Список объявлений', path: PATHS.home },
  { name: 'Просмотры', path: PATHS.views },
  { name: 'Список агентов', path: PATHS.agents },
  // { name: 'Шаблоны жилья/здания', path: PATHS.samplesCustomFields }, TEMPORARILY COMMENTED SECTIONS FLOW
  { name: 'Шаблоны тегов', path: PATHS.samplesTags },
  { name: 'SEO теги', path: PATHS.seo },
];

export const floorCount = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
];

export const DEFAULT_DROPZONE_OPTIONS = ({ isVideo, isSvg }) => {
  return {
    accept: isVideo
      ? { 'video/mp4': [] }
      : isSvg
      ? {
          'image/*': [],
        }
      : {
          'image/jpeg': [],
          'image/png': [],
        },
  };
};

export const SUCCESS_MESSAGES = {
  ADD_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно создано',
  EDIT_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно изменено',
  DELETE_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно удалено',
  ADD_AGENT_SUCCESS: 'Агент успешно создан',
  EDIT_AGENT_SUCCESS: 'Агент успешно измененен',
  DELETE_AGENT_SUCCESS: 'Агент успешно удален',
  ADD_TEMPLATE_SUCCESS: 'Шаблон успешно создан',
  EDIT_TEMPLATE_SUCCESS: 'Шаблон успешно измененен',
  DELETE_TEMPLATE_SUCCESS: 'Шаблон успешно удален',
  ADD_METATAGS_SUCCESS: 'Сео теги для страницы успешно созданы',
  EDIT_METATAGS_SUCCESS: 'Сео теги для страницы успешно измененены',
  DELETE_METATAGS_SUCCESS: 'Сео теги для страницы успешно удалены',
};

export const ERRORS_MESSAGES = {
  ERRORS_FIELDS_REQUIRED:
    'Заполните корректно все обязательные поля для всех языков',
  ERRORS_FIELDS_REQUIRED_NOLANG: 'Заполните корректно все обязательные поля',
};

export const tableHeadItems = [
  { title: 'ID' },
  { title: 'Тип предложения' },
  { title: 'Избранное' },
  { title: 'Заглавное фото' },
  { title: 'Заголовок' },
  { title: 'Цена, €' },
  { title: 'Общ. площадь, м2' },
  { title: 'Действия' },
];

export const tableHeadAgentItems = [
  { title: 'ID' },
  { title: 'Фото агента' },
  { title: 'Имя агента' },
  { title: 'Агенство' },
  { title: 'Номер телефона' },
  { title: 'Действия' },
];

export const tableHeadSamplesCustomFieldsItems = [
  { title: 'ID' },
  { title: 'Имя шаблона' },
  { title: 'Имя секции' },
  { title: 'Действия' },
];

export const tableHeadSamplesTagsItems = [
  { title: 'ID' },
  { title: 'Иконка' },
  { title: 'Имя шаблона' },
  { title: 'Действия' },
];

export const tableHeadViewsItems = [
  { title: 'ID' },
  { title: 'Уник. просмотров' },
  { title: 'Просмотров' },
  { title: 'Кликов на тел' },
  { title: 'Кликов на мессенджеры' },
  { title: 'Просмотров 3д тура' },
  { title: 'Просмотров видео до конца' },
];

export const tableHeadMetatagsItems = [
  { title: 'ID' },
  { title: 'Адрес' },
  { title: 'Индексация' },
  { title: 'Действия' },
];

export const fieldsArrayNames = {
  title: 'title',
  district: 'district',
  tagsAboutHomeName: 'tagsAboutHome',
  tagsInnerName: 'tagsInner',
  tagsMapName: 'tagsMap',
  tagsRulesName: 'tagsRules',
  city: 'city',
  optionGeneralName: 'optionGeneral',
  tagsCommunicationsName: 'tagsCommunications',
  description: 'description',
  tagsAboutObjectName: 'tagsAboutObject',
  complexName: 'complexName',
};

export const tagsSortNames = {
  optionGeneral: 'general',
  tagsAboutHome: 'aboutHome',
  tagsInner: 'inner',
  tagsMap: 'map',
  tagsRules: 'rules',
  tagsCommunications: 'communications',
  tagsAboutObject: 'aboutObject',
};

export const annoucementsPagination = {
  limit: 10,
};

export const agentsPagination = {
  limit: 10,
};

export const metaTagsPagination = {
  limit: 15,
};

export const customFieldsPagination = {
  limit: 15,
};

export const templateTagPagination = {
  limit: 15,
};

export const statisticPagination = {
  limit: 25,
};

export const landAreaTypeOptions = [
  { value: 'HECTARE', label: 'Гектар' },
  { value: 'AR', label: 'Сотка' },
];

export const typeOfferOptions = [
  { value: 'SELL', label: 'Продажа' },
  { value: 'RENT', label: 'Аренда' },
  { value: 'DAILY', label: 'Посуточно' },
];

export const OFFER_TYPES = {
  SELL: 'SELL',
  RENT: 'RENT',
  DAILY: 'DAILY',
};

export const typePropertyOptions = [
  { value: 'apartments', label: 'Квартира' },
  { value: 'house', label: 'Дом' },
  { value: 'commercial', label: 'Коммерческая' },
  { value: 'plot', label: 'Участок' },
];

export const PROPERTY_TYPES = {
  APARTMENTS: 'apartments',
  HOUSE: 'house',
  COMMERCIAL: 'commercial',
  PLOT: 'plot',
};

export const typeOfPropertyOptions = [
  { value: 'new', label: 'Новострой/Clădire nouă/New building' },
  { value: 'secondary', label: 'Вторичка/Secundară/Secondary' },
];

export const typeOfCommercialOptions = [
  { value: 'office', label: 'Офис' },
  { value: 'warehouse', label: 'Складское помещение' },
  { value: 'undertrade', label: 'Торговое помещение' },
  { value: 'free', label: 'Помещение свободного назначения' },
  { value: 'foodservice', label: 'Общепит' },
  { value: 'production', label: 'Производственное помещение' },
  { value: 'carservice', label: 'Автосервис' },
  { value: 'building', label: 'Здание' },
  { value: 'business', label: 'Готовый бизнес' },
  { value: 'conferencehall', label: 'Конференц-зал' },
  { value: 'hotel', label: 'Гостиница' },
];

export const COMMERCIAL_TYPES = {
  OFFICE: 'office',
  UNDER_TRADE: 'undertrade',
  WAREHOUSE: 'warehouse',
  FREE: 'free',
  FOOD_SERVICE: 'foodservice',
  PRODUCTION: 'production',
  CAR_SERVICE: 'carservice',
  BUILDING: 'building',
  BUSINESS: 'business',
  CONFERENCE_HALL: 'conferencehall',
  HOTEL: 'hotel',
};

export const citiesOptions = [
  { value: 'chisinau', label: 'Кишинев/Chisinau/Chisinau' },
  { value: 'mdcityother', label: 'Другие населенные пункты' },
  { value: 'other', label: 'Свободная пропись' }, // required option
];

export const districtsOptions = [
  { value: 'aeroport', label: 'Аэропорт/Aeroport/Aeroport' },
  { value: 'buiucani', label: 'Буюканы/Buiucani/Buiucani' },
  { value: 'sculeanca', label: 'Скулянка/Sculeanca/Sculeanca' },
  { value: 'posta-veche', label: 'Старая почта/Poșta Veche/Poșta Veche' },
  { value: 'riscani', label: 'Рышкановка/Rîșcani/Rîșcani' },
  { value: 'ciocana', label: 'Чеканы/Ciocana/Ciocana' },
  { value: 'centru', label: 'Центр/Centru/Centru' },
  { value: 'botanica', label: 'Ботаника/Botanica/Botanica' },
  { value: 'telecentru', label: 'Телецентр/Telecentru/Telecentru' },
  { value: 'durlesti', label: 'Дурлешты/Durlești/Durlești' },
  { value: 'other', label: 'Свободная пропись' }, // required option
];

export const mdCityOtherOptions = [
  { value: 'bacioi', label: 'Бачой/Bacioi/Bacioi' },
  { value: 'braila', label: 'Браила/Braila/Braila' },
  { value: 'bubuechi', label: 'Бубуечь/Bubuechi/Bubuechi' },
  { value: 'budesti', label: 'Будешты/Budești/Budesti' },
  { value: 'buneti', label: 'Бунец/Buneti/Buneti' },
  { value: 'bic', label: 'Бык/Bîc/Bic' },
  { value: 'vatra', label: 'Ватра/Vatra/Vatra' },
  {
    value: 'vadulluivoda',
    label: 'Вудул-луй-Водэ/Vadul lui Vodă/Vadul lui Voda',
  },
  { value: 'vaduleni', label: 'Вэдулень/Väduleni/Vaduleni' },
  { value: 'guidigic', label: 'Гидигич/Guidigic/Guidigic' },
  { value: 'goian', label: 'Гоян/Goian/Goian' },
  { value: 'gratiesti', label: 'Гратиешты/Grätiesti/Gratiesti' },
  { value: 'hulboaca', label: 'Гульбоака/Hulboaca/Hulboaca' },
  { value: 'dobrogea', label: 'Добружа/Dobrogea/Dobrogea' },
  { value: 'dumbrava', label: 'Думбрава/Dumbrava/Dumbrava' },
  { value: 'keltuitor', label: 'Келтуитор/Keltuitor/Keltuitor' },
  { value: 'codru', label: 'Кодру/Codru/Codru' },
  { value: 'colonita', label: 'Колоница/Colonita/Colonita' },
  { value: 'condrita', label: 'Кондрица/Condrita/Condrita' },
  { value: 'cricova', label: 'Криково/Cricova/Cricova' },
  { value: 'cruzesti', label: 'Крузешты/Cruzesti/Cruzesti' },
  { value: 'goianulnou', label: 'Новые Гояны/Goianul Nou/Goianul Nou' },
  { value: 'revaca', label: 'Ревака/Revaca/Revaca' },
  { value: 'stauceni', label: 'Ставчены/Stäuceni/Stauceni' },
  { value: 'straisteni', label: 'Страйстены/Străisteni/Straisteni' },
  { value: 'singera', label: 'Сынжера/Sîngera/Singera' },
  { value: 'tohatin', label: 'Тогатин/Tohatin/Tohatin' },
  { value: 'truseni', label: 'Трушены/Truseni/Truseni' },
  { value: 'fauresti', label: 'Фаурешты/Fäuresti/Fauresti' },
  { value: 'frumusica', label: 'Фрумушика/Frumusica/Frumusica' },
  { value: 'humulesti', label: 'Хумулешть/Humulesti/Humulesti' },
  { value: 'ceroborta', label: 'Чероборта/Ceroborta/Ceroborta' },
  { value: 'ciorescu', label: 'Чореску/Ciorescu/Ciorescu' },
];

export const appointmentOptions = [
  { value: 'office', label: 'Офис/Birou/Office' },
  {
    value: 'undertrade',
    label: 'Торговое помещение/Spațiu de depozitare/Commercial premise',
  },
  {
    value: 'warehouse',
    label: 'Складское помещение/Spații comerciale/Warehouse',
  },
  {
    value: 'free',
    label: 'Помещение свободного назначения/Spațiu liber destinat/Free space',
  },
  {
    value: 'foodservice',
    label: 'Общепит/Catering public/Catering',
  },
  {
    value: 'production',
    label: 'Производственное помещение/Spații industriale/Industrial premises',
  },
  { value: 'carservice', label: 'Автосервис/Autoservice/Car service' },
  { value: 'building', label: 'Здание/Clădire/Building' },
  {
    value: 'business',
    label: 'Готовый бизнес/Afaceri pregătite/Ready business',
  },
  {
    value: 'conferencehall',
    label: 'Конференц-зал/Sală de conferințe/Conference hall',
  },
  { value: 'hotel', label: 'Гостиница/Hotel/Hotel' },
  {
    value: 'undercounstuction',
    label: 'Строительство/Construcții/Construction',
  },
  { value: 'stock', label: 'Фермерство/Fermă/Farming' },
];

export const buildingTypeOptions = [
  {
    value: 'businesscenter',
    label: 'Бизнес-центр/Centru de afaceri/Business center',
  },
  {
    value: 'shopingmall',
    label: 'Торговый центр/Centru comercial/Shopping center',
  },
  {
    value: 'warehousecomplex',
    label: 'Складской комплекс/Complex de depozite/Warehouse complex',
  },
  {
    value: 'separatebuild',
    label: 'Отдельное здание/Clădire independentă/Separate building',
  },
  {
    value: 'house',
    label: 'Жилой дом/Clădire rezidențială/Residential building',
  },
];

export const heatingOptions = [
  { value: 'center', label: 'Центральное/Centrală/Central' },
  { value: 'autonomous', label: 'Автономное/Autonomă/Autonomous' },
];

export const advantagesOptions = [
  { value: 'isInResidentialComplex', label: 'В жилом комплексе' },
  { value: 'isFullyFurnished', label: 'Полностью мебелированно' },
  { value: 'isParking', label: 'Парковка' },
  { value: 'isBalcony', label: 'Балкон/Лоджия' },
  { value: 'isNearBodyOfWater', label: 'Рядом водоем' },
  { value: 'isAccess24_7', label: 'Доступ 24/7' },
  { value: 'isNearForest', label: 'Рядом лес' },
  { value: 'isPanoramicWindows', label: 'Панорамные окна' },
  { value: 'isParkView', label: 'Вид на парк' },
  { value: 'isTerrace', label: 'Терраса' },
  { value: 'isBidet', label: 'Биде' },
  { value: 'isHavePool', label: 'Бассейн' },
  { value: 'isHaveGarage', label: 'Гараж' },
  { value: 'isHavePlot', label: 'Есть участок' },
  { value: 'isNearPark', label: 'Парковая зона' },
];

export const advantagesOptionsValues = {
  isInResidentialComplex: 'isInResidentialComplex',
  isFullyFurnished: 'isFullyFurnished',
  isParking: 'isParking',
  isBalcony: 'isBalcony',
  isNearBodyOfWater: 'isNearBodyOfWater',
  isAccess24_7: 'isAccess24_7',
  isNearForest: 'isNearForest',
  isPanoramicWindows: 'isPanoramicWindows',
  isParkView: 'isParkView',
  isTerrace: 'isTerrace',
  isBidet: 'isBidet',
  isHavePool: 'isHavePool',
  isHaveGarage: 'isHaveGarage',
  isHavePlot: 'isHavePlot',
  isNearPark: 'isNearPark',
};

export const filteredAdvantagesOptions = {
  [PROPERTY_TYPES.APARTMENTS]: [
    advantagesOptionsValues.isInResidentialComplex,
    advantagesOptionsValues.isFullyFurnished,
    advantagesOptionsValues.isParking,
    advantagesOptionsValues.isBalcony,
    advantagesOptionsValues.isPanoramicWindows,
    advantagesOptionsValues.isParkView,
    advantagesOptionsValues.isTerrace,
    advantagesOptionsValues.isBidet,
  ],
  [PROPERTY_TYPES.HOUSE]: [
    advantagesOptionsValues.isParking,
    advantagesOptionsValues.isFullyFurnished,
    advantagesOptionsValues.isHaveGarage,
    advantagesOptionsValues.isPanoramicWindows,
    advantagesOptionsValues.isHavePool,
    advantagesOptionsValues.isHavePlot,
    advantagesOptionsValues.isNearPark,
  ],
  [PROPERTY_TYPES.COMMERCIAL]: [
    advantagesOptionsValues.isAccess24_7,
    advantagesOptionsValues.isParking,
  ],
  [PROPERTY_TYPES.PLOT]: [
    advantagesOptionsValues.isNearBodyOfWater,
    advantagesOptionsValues.isNearForest,
  ],
};

export const renovationOptions = [
  { value: 'designer_renovation', label: 'Дизайнерский ремонт' },
  // { value: 'redecoration', label: 'Косметический ремонт' }, // TEMPORARY COMMENTED
  { value: 'euro_renovation', label: 'Евроремонт' },
  // { value: 'needs_renovation', label: 'Нуждается в ремонте' }, // TEMPORARY COMMENTED
  { value: 'white_version', label: 'Белый вариант' },
  { value: 'gray_version', label: 'Серый вариант' },
];

export const houseTypeOptions = [
  { value: 'duplex', label: 'Дуплекс' },
  { value: 'triplex', label: 'Триплекс' },
  { value: 'separate', label: 'Отдельный дом' },
];

export const SEO_METATAGS_EDITOR_TYPES = [
  { label: 'ЗАГОЛОВОК H2', style: 'header-two' },
  { label: 'СПИСОК', style: 'unordered-list-item' },
];

export const readinessOptions = [
  { value: 'readiness', label: 'Сдан в эксплуатацию' },
  { value: 'underConstruction', label: 'На этапе строительства' },
];
export const tagsBlocksOptions = [
  { value: 'optionGeneral', label: 'Опции основного блока' },
  { value: 'tagsInner', label: 'Что находится внутри' },
  { value: 'tagsAboutHome', label: 'Информация о доме' },
  { value: 'tagsAboutObject', label: 'Информация об объекте' },
  { value: 'tagsCommunications', label: 'Коммуникации' },
  { value: 'tagsMap', label: 'Места вокруг' },
  { value: 'tagsRules', label: 'Правила в доме' },
];

export const MODAL_NAME = {
  SAMPLES_TAGS: 'SAMPLES_TAGS',
  NO_MODAL: 'NO_MODAL',
};
