import { OFFER_TYPES, PROPERTY_TYPES } from 'constants';
import { getFieldLabelLang } from 'utils';

export const announcementInfo = ({
  data,
  mapImgData,
  imagesData,
  videoData,
  seoImgData,
  isEditMode,
}) => {
  if (data) {
    const isRent = data.announcementType?.value === OFFER_TYPES.RENT;
    const propertyType = data.propertyType?.value;
    const isChisinau = data.city?.value === 'chisinau';
    const isDailyType = data.announcementType?.value === OFFER_TYPES.DAILY;
    const isHouseType = propertyType === PROPERTY_TYPES.HOUSE;
    const isPlotType = propertyType === PROPERTY_TYPES.PLOT;
    const isApartmentsType = propertyType === PROPERTY_TYPES.APARTMENTS;
    const isCommercialType = propertyType === PROPERTY_TYPES.COMMERCIAL;

    // const abouthouseRU = handleAddOrder(data.aboutHousingRU);
    // const abouthouseMD = handleAddOrder(data.aboutHousingMD);
    // const abouthouseEN = handleAddOrder(data.aboutHousingEN);
    // const aboutbuildRU = handleAddOrder(data.aboutTheBuildingRU);
    // const aboutbuildMD = handleAddOrder(data.aboutTheBuildingMD);
    // const aboutbuildEN = handleAddOrder(data.aboutTheBuildingEN);
    // const aboutareaRU = handleAddOrder(data.aboutAreaRU);
    // const aboutareaMD = handleAddOrder(data.aboutAreaMD);
    // const aboutareaEN = handleAddOrder(data.aboutAreaEN); TEMPORARILY COMMENTED SECTIONS FLOW

    // const getCustomFields = () => {
    //   switch (propertyType) {
    //     case 'house':
    //       return [
    //         ...abouthouseRU,
    //         ...abouthouseMD,
    //         ...abouthouseEN,
    //         ...aboutareaRU,
    //         ...aboutareaMD,
    //         ...aboutareaEN,
    //       ];
    //     case 'apartments':
    //       return [
    //         ...abouthouseRU,
    //         ...abouthouseMD,
    //         ...abouthouseEN,
    //         ...aboutbuildRU,
    //         ...aboutbuildMD,
    //         ...aboutbuildEN,
    //       ];
    //     default:
    //       return [
    //         ...abouthouseRU,
    //         ...abouthouseMD,
    //         ...abouthouseEN,
    //         ...aboutbuildRU,
    //         ...aboutbuildMD,
    //         ...aboutbuildEN,
    //         ...aboutareaRU,
    //         ...aboutareaMD,
    //         ...aboutareaEN,
    //       ];
    //   }
    // };

    // const customFields = getCustomFields(); TEMPORARILY COMMENTED SECTIONS FLOW

    const tagsRU = [
      ...((isRent || isDailyType) && data.tagsRulesRU ? data.tagsRulesRU : []),
      ...((isHouseType || isPlotType) && data.tagsCommunicationsRU
        ? data.tagsCommunicationsRU
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) &&
      data.tagsAboutHomeRU
        ? data.tagsAboutHomeRU
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) && data.tagsInnerRU
        ? data.tagsInnerRU
        : []),
      ...data.tagsMapRU,
      ...data.optionGeneralRU,
      ...((isCommercialType || isPlotType) && data.tagsAboutObjectRU
        ? data.tagsAboutObjectRU
        : []),
    ];
    const tagsMD = [
      ...((isRent || isDailyType) && data.tagsRulesMD ? data.tagsRulesMD : []),
      ...((isHouseType || isPlotType) && data.tagsCommunicationsMD
        ? data.tagsCommunicationsMD
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) &&
      data.tagsAboutHomeMD
        ? data.tagsAboutHomeMD
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) && data.tagsInnerMD
        ? data.tagsInnerMD
        : []),
      ...data.tagsMapMD,
      ...data.optionGeneralMD,
      ...((isCommercialType || isPlotType) && data.tagsAboutObjectMD
        ? data.tagsAboutObjectMD
        : []),
    ];
    const tagsEN = [
      ...((isRent || isDailyType) && data.tagsRulesEN ? data.tagsRulesEN : []),
      ...((isHouseType || isPlotType) && data.tagsCommunicationsEN
        ? data.tagsCommunicationsEN
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) &&
      data.tagsAboutHomeEN
        ? data.tagsAboutHomeEN
        : []),
      ...((isDailyType || isApartmentsType || isHouseType) && data.tagsInnerEN
        ? data.tagsInnerEN
        : []),
      ...data.tagsMapEN,
      ...data.optionGeneralEN,
      ...((isCommercialType || isPlotType) && data.tagsAboutObjectEN
        ? data.tagsAboutObjectEN
        : []),
    ];

    return isEditMode
      ? {
          announcementInfo: {
            RU: {
              id: data.idRU,
              announcementId: data.announcementId,
              title: data.titleRU,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 1)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 1),
              tags: tagsRU,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 1)
                      : getFieldLabelLang(data.mdcityother?.label, 1),
                  }
                : { city: data.cityRU }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 1) }
                : { district: data.districtRU }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionRU,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 1)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 1)
                : null,
              complexName: isApartmentsType ? data.complexNameRU : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
            MD: {
              id: data.idMD,
              announcementId: data.announcementId,
              title: data.titleMD,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 2)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 2),
              tags: tagsMD,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 2)
                      : getFieldLabelLang(data.mdcityother?.label, 2),
                  }
                : { city: data.cityMD }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 2) }
                : { district: data.districtMD }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionMD,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 2)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 2)
                : null,
              complexName: isApartmentsType ? data.complexNameMD : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
            EN: {
              id: data.idEN,
              announcementId: data.announcementId,
              title: data.titleEN,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 3)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 3),
              tags: tagsEN,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 3)
                      : getFieldLabelLang(data.mdcityother?.label, 3),
                  }
                : { city: data.cityEN }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 3) }
                : { district: data.districtEN }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionEN,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 3)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 3)
                : null,
              complexName: isApartmentsType ? data.complexNameEN : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
          },
          agentId: +data.agents?.value,
          id: data.announcementId,
          mapImage: mapImgData?.[0]?.token || data.mapimgToken,
          seoImage: seoImgData?.[0]?.token || data.seoimgToken,
          price: +data.price,
          link3D: !data.isAffiliateAnnouncement ? data.link : null,
          video: !data.isAffiliateAnnouncement
            ? videoData?.token || data.videoToken
            : null,
          roomsCount:
            (!data.isStudio && !isCommercialType && !isPlotType) || isHouseType
              ? +data.rooms
              : null,
          areaTotal: !isPlotType ? +data.areafull : null,
          landArea: isHouseType || isPlotType ? +data.landarea : null,
          landAreaType:
            isHouseType || isPlotType ? data.landareaType?.value : null,
          floor: isApartmentsType || isCommercialType ? data.floor.value : null,
          floorCount: !isPlotType ? data.floorcount?.value : null,
          images: imagesData || data.photos,
          announcementType: data.announcementType?.value,
          propertyType: data.propertyType?.value,
          showAddress: isChisinau ? data.showAddress : false,
          commercialType: isCommercialType ? data.commercialType?.value : null,
          isFavorite: data.isFavorite,
          isStudio: !isApartmentsType ? false : data.isStudio,
          isActive: !data.isNotActive,
          // customFields: customFields, TEMPORARILY COMMENTED SECTIONS FLOW
          isAdvertisement: data.isAdvertisement,
          isAffiliateAnnouncement: data.isAffiliateAnnouncement,
          isOtherCity: data.isOtherCity,
          linkMap: data.linkMap,
          isInResidentialComplex: isApartmentsType
            ? data.isInResidentialComplex
            : false,
          isFullyFurnished:
            isApartmentsType || isHouseType ? data.isFullyFurnished : false,
          isParking:
            isApartmentsType || isHouseType || isCommercialType
              ? data.isParking
              : false,
          isBalcony: isApartmentsType ? data.isBalcony : false,
          isNearBodyOfWater:
            isHouseType || isPlotType ? data.isNearBodyOfWater : false,
          isAccess24_7: isCommercialType ? data.isAccess24_7 : false,
          isNearForest: isPlotType ? data.isNearForest : false,
          isPanoramicWindows:
            isApartmentsType || isHouseType ? data.isPanoramicWindows : false,
          isParkView: isApartmentsType ? data.isParkView : false,
          isTerrace: isApartmentsType ? data.isTerrace : false,
          isBidet: isApartmentsType ? data.isBidet : false,
          isHavePool: isHouseType ? data.isHavePool : false,
          isHaveGarage: isHouseType ? data.isHaveGarage : false,
          isHavePlot: isHouseType ? data.isHavePlot : false,
          isNearPark: isHouseType ? data.isNearPark : false,
          apartmentRenovation:
            isApartmentsType || isHouseType
              ? data.apartmentRenovation.value
              : null,
          houseType: isHouseType ? data.houseType.value : null,
          readinessType: isApartmentsType ? data.readinessType.value : null,
        }
      : {
          announcementInfo: {
            RU: {
              title: data.titleRU,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 1)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 1),
              tags: tagsRU,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 1)
                      : getFieldLabelLang(data.mdcityother?.label, 1),
                  }
                : { city: data.cityRU }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 1) }
                : { district: data.districtRU }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionRU,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 1)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 1)
                : null,
              complexName: isApartmentsType ? data.complexNameRU : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
            MD: {
              title: data.titleMD,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 2)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 2),
              tags: tagsMD,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 2)
                      : getFieldLabelLang(data.mdcityother?.label, 2),
                  }
                : { city: data.cityMD }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 2) }
                : { district: data.districtMD }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionMD,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 2)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 2)
                : null,
              complexName: isApartmentsType ? data.complexNameMD : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
            EN: {
              title: data.titleEN,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty: isApartmentsType
                ? getFieldLabelLang(data.typeOfProperty?.label, 3)
                : null,
              heating:
                isCommercialType || isPlotType
                  ? null
                  : getFieldLabelLang(data.heating?.label, 3),
              tags: tagsEN,
              ...(data.city?.value !== 'other'
                ? {
                    city: isChisinau
                      ? getFieldLabelLang(data.city?.label, 3)
                      : getFieldLabelLang(data.mdcityother?.label, 3),
                  }
                : { city: data.cityEN }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 3) }
                : { district: data.districtEN }),
              house: isChisinau ? data.houseChisinau?.label : null,
              description: data.descriptionEN,
              appointment:
                isCommercialType || isPlotType
                  ? getFieldLabelLang(data.appointment?.label, 3)
                  : null,
              buildingType: isCommercialType
                ? getFieldLabelLang(data.buildingType?.label, 3)
                : null,
              complexName: isApartmentsType ? data.complexNameEN : null,
              yearCompleted:
                isApartmentsType && Boolean(data.yearCompleted)
                  ? String(data.yearCompleted)
                  : null,
            },
          },
          agentId: +data.agents?.value,
          mapImage: mapImgData[0]?.token,
          seoImage: seoImgData[0]?.token,
          price: data.price,
          link3D: !data.isAffiliateAnnouncement ? data.link : null,
          video: !data.isAffiliateAnnouncement ? videoData?.token : null,
          roomsCount:
            (!data.isStudio && !isCommercialType && !isPlotType) || isHouseType
              ? +data.rooms
              : null,
          areaTotal: !isPlotType ? +data.areafull : null,
          landArea: isHouseType || isPlotType ? +data.landarea : null,
          landAreaType:
            isHouseType || isPlotType ? data.landareaType?.value : null,
          floor: isApartmentsType || isCommercialType ? data.floor.value : null,
          floorCount:
            propertyType !== PROPERTY_TYPES.PLOT ? data.floorcount.value : null,
          images: imagesData,
          announcementType: data.announcementType?.value,
          propertyType: data.propertyType?.value,
          showAddress: isChisinau ? data.showAddress : false,
          commercialType: isCommercialType ? data.commercialType?.value : null,
          isFavorite: data.isFavorite,
          isStudio: !isApartmentsType ? false : data.isStudio,
          isActive: !data.isNotActive,
          // customFields: customFields, TEMPORARILY COMMENTED SECTIONS FLOW
          isAdvertisement: data.isAdvertisement,
          isAffiliateAnnouncement: data.isAffiliateAnnouncement,
          isOtherCity: data.isOtherCity,
          linkMap: data.linkMap,
          isInResidentialComplex: isApartmentsType
            ? data.isInResidentialComplex
            : false,
          isFullyFurnished:
            isApartmentsType || isHouseType ? data.isFullyFurnished : false,
          isParking:
            isApartmentsType || isHouseType || isCommercialType
              ? data.isParking
              : false,
          isBalcony: isApartmentsType ? data.isBalcony : false,
          isNearBodyOfWater:
            isHouseType || isPlotType ? data.isNearBodyOfWater : false,
          isAccess24_7: isCommercialType ? data.isAccess24_7 : false,
          isNearForest: isPlotType ? data.isNearForest : false,
          isPanoramicWindows:
            isApartmentsType || isHouseType ? data.isPanoramicWindows : false,
          isParkView: isApartmentsType ? data.isParkView : false,
          isTerrace: isApartmentsType ? data.isTerrace : false,
          isBidet: isApartmentsType ? data.isBidet : false,
          isHavePool: isHouseType ? data.isHavePool : false,
          isHaveGarage: isHouseType ? data.isHaveGarage : false,
          isHavePlot: isHouseType ? data.isHavePlot : false,
          isNearPark: isHouseType ? data.isNearPark : false,
          apartmentRenovation:
            isApartmentsType || isHouseType
              ? data.apartmentRenovation.value
              : null,
          houseType: isHouseType ? data.houseType.value : null,
          readinessType: isApartmentsType ? data.readinessType.value : null,
        };
  }
};
