import styled from 'styled-components';

export const ListTitle = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background: #fff;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 2px;
  box-shadow: 0px 2px 10px rgba(21, 21, 21, 0.04);
`;

export const TitleInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.addEdit ? '810px' : 'initial')};
  background: #fff;
`;

export const TitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`;

export const AddNewBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;

  a {
    width: 100%;
    max-width: 343px;
    @media (max-width: 768px) {
      max-width: initial;
    }
  }
`;

export const AddNewButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  max-width: 343px;
  height: 52px;
  background: #161e41;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    max-width: initial;
  }
`;

export const AddText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 8px;
`;

export const TableWrapper = styled.div`
  overflow-x: scroll;
`;

export const MetatagsListTable = styled.table`
  width: 100%;
  padding: 16px;
  border-spacing: initial;
  min-width: 768px;
`;

export const TableHead = styled.tr`
  td {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #fff;
    background: #161e41;
    text-align: center;
    padding: 8px;
  }
`;

export const TableRow = styled.tr`
  td {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #000;
    background: #fff;
    text-align: center;
    padding: 8px;
    border-top: 1px solid #dbdfe8;
  }
`;

export const TableBody = styled.tbody``;
export const IdCell = styled.td``;
export const AdressCell = styled.td``;
export const IndexCell = styled.td``;

export const ActionsCell = styled.td`
  white-space: nowrap;
`;

export const EditButton = styled.button`
  padding: 12px 6px;
  background: #161e41;
  border-radius: 6px;
  border: none;
  color: #fff;
  width: 100px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

export const DeleteButton = styled.button`
  padding: 12px 6px;
  background: #161e41;
  border-radius: 6px;
  margin-left: 8px;
  border: none;
  color: #fff;
  width: 100px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const WrapperLoader = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

export const NotFound = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

// AddEditSeoMetatags styles

export const AddSeoMetatagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 26px;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const AddSeoMetatagsCreateTitle = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background: #fff;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 2px;
  box-shadow: 0px 2px 10px rgba(21, 21, 21, 0.04);
`;

export const AddSeoMetatagsTitleInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 810px;
  background: #fff;
`;

export const AddSeoMetatagsTitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`;

export const LanguageTabsWrapper = styled.div`
  display: flex;
  background: #fff;
  height: 52px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-bottom: 1px solid #eef2fa;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    border-top: 1px solid #eef2fa;
  }
`;

export const LanguageItem = styled.div`
  display: flex;
  width: 100%;
  max-width: 270px;
  height: 52px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #161e41;
  border-bottom: ${(props) => (props.active ? '2px solid #161E41' : 'none')};
  cursor: pointer;
`;

export const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 810px;
  justify-content: flex-end;
  margin-top: 16px;

  @media (max-width: 816px) {
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
    background: #fff;
    height: 92px;
    padding: 16px 16px 0 16px;
    justify-content: space-between;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 164px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: #d8ddf1;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0;
  :hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 42px;
    max-width: initial;
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 164px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: #161e41;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 30px;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    height: 42px;
    max-width: initial;
  }
`;

export const ButtonText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${(props) => (props.active ? '#ffffff' : '#9b9ea7')};
  margin-left: 6px;
`;
