import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { AddIcon, DeleteIcon, EditPhotoIcon } from 'assets';

import { DEFAULT_DROPZONE_OPTIONS } from 'constants';
import { useDropzone } from 'react-dropzone';
import VideoPreviewSrc from 'assets/video.png';

import MultipleImageComponent from './MultipleImage';
import {
  AddMultipleNew,
  DefaultContent,
  DeleteBox,
  DropBackgroundImg,
  DropText,
  DropzoneImg,
  DropZoneWrapper,
  EditPhotoBox,
  HelperTextPhoto,
  MultipleContainer,
  PhotosBlock,
  StyledImage,
  TitlePhoto,
} from './styles';

const Dropzone = ({
  name,
  dropText,
  single,
  imgBg,
  circle,
  multiple,
  onChange,
  value,
  hasError,
  isVideo,
  isSvg,
}) => {
  const DROPZONE_OPTIONS = DEFAULT_DROPZONE_OPTIONS({ isVideo, isSvg });

  const onDrop = (acceptedFiles) => {
    onChange(
      multiple
        ? [
            ...(value || []),
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
          ]
        : Object.assign(acceptedFiles[0], {
            preview: isVideo
              ? VideoPreviewSrc
              : URL.createObjectURL(acceptedFiles[0]),
          }),
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...DROPZONE_OPTIONS,
    onDrop,
    multiple: multiple,
  });

  const deletePreviewImage = (item) => {
    onChange(
      value.length > 1
        ? value.filter((photo) => photo.preview !== item.preview)
        : null,
    );
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const startIndex = value
        .map((item) => item.preview)
        .indexOf(active.id.preview);
      const endIndex = value
        .map((item) => item.preview)
        .indexOf(over.id.preview);
      const [removed] = value.splice(startIndex, 1);
      value.splice(endIndex, 0, removed);
      onChange(value);
    }
  };

  return (
    <DropZoneWrapper circle={circle} multiple={multiple}>
      {value ? (
        multiple && Array.isArray(value) ? (
          <MultipleContainer>
            <TitlePhoto>Фото</TitlePhoto>
            <DndContext
              autoScroll={false}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <PhotosBlock>
                <SortableContext items={value} strategy={rectSortingStrategy}>
                  {value.map((item, index) => {
                    return (
                      <MultipleImageComponent
                        key={index}
                        item={item}
                        deletePreviewImage={deletePreviewImage}
                      />
                    );
                  })}
                </SortableContext>
                <AddMultipleNew {...getRootProps()}>
                  <input
                    type="file"
                    name={name}
                    {...getInputProps({ onChange })}
                  />
                  <AddIcon fill={'#fff'} />
                </AddMultipleNew>
              </PhotosBlock>
            </DndContext>
            <HelperTextPhoto>
              Первое фото будет на обложке объявления. Перетащите чтоб изменить
              порядок
            </HelperTextPhoto>
            {hasError && hasError.ref.name === 'photos' ? (
              <HelperTextPhoto hasError>{hasError.message}</HelperTextPhoto>
            ) : null}
          </MultipleContainer>
        ) : (
          <DropzoneImg circle={circle} isVideo={isVideo} isSvg={isSvg}>
            <StyledImage
              src={isVideo ? VideoPreviewSrc : value.preview}
              alt="i"
              circle={circle}
              isVideo={isVideo}
              isSvg={isSvg}
            />
            {isVideo && 'video.mp4'}
            {circle ? (
              <EditPhotoBox {...getRootProps()}>
                <input
                  type="file"
                  name={name}
                  {...getInputProps({ onChange })}
                />
                <EditPhotoIcon />
              </EditPhotoBox>
            ) : (
              <DeleteBox onClick={deletePreviewImage}>
                <DeleteIcon />
              </DeleteBox>
            )}
          </DropzoneImg>
        )
      ) : (
        <DefaultContent {...getRootProps()}>
          <input type="file" name={name} {...getInputProps({ onChange })} />
          <DropBackgroundImg
            circle={circle}
            single={single}
            hasError={hasError}
          >
            {imgBg}
            {dropText && <DropText>{dropText}</DropText>}
          </DropBackgroundImg>
        </DefaultContent>
      )}
    </DropZoneWrapper>
  );
};

export default Dropzone;
