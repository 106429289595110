import { agentsPagination } from 'constants';
import { useEffect, useState } from 'react';

export const useAgentsPagination = ({ agents, fetchNextPage }) => {
  const [offset, setOffset] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const paginatedAgentsData = () => {
    let result = [];
    agents?.pages?.forEach((item) => {
      result.push(...item.rows);
    });
    return result;
  };

  const agentsCurrentData = paginatedAgentsData();

  const isMoreAgents = agents?.pages[0]?.count > agentsCurrentData.length;

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      isMoreAgents
    ) {
      setIsDataLoading(true);
    }
  };

  useEffect(() => {
    if (isDataLoading && isMoreAgents) {
      setOffset((prev) => {
        return prev + agentsPagination.limit;
      });
    }
  }, [isDataLoading]);

  useEffect(() => {
    offset > 0 &&
      fetchNextPage({
        pageParam: {
          limit: agentsPagination.limit,
          offset: offset,
        },
      }).then(() => {
        setIsDataLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    if (isMoreAgents) {
      document.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [agents?.pages[0]?.count, agentsCurrentData, isMoreAgents]);

  return { agentsCurrentData };
};
