export const annoucementsByLanguage = ({
  announcements,
  currentLanguage = { value: 1 },
}) => {
  return announcements?.map((announcement) => {
    const announcementInfo = announcement?.announcementInfo?.filter(
      (item) => item?.languageId === currentLanguage?.value,
    )[0];

    const customFields = announcement?.customFields?.filter(
      (item) => item?.languageId === currentLanguage?.value,
    );
    return { ...announcement, announcementInfo, customFields };
  });
};
