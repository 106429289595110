import { OFFER_TYPES, PROPERTY_TYPES, fieldsArrayNames } from 'constants/index';
import {
  getSampleTagsFields,
  getSampleTagsFieldsEdit,
} from 'utils/getSampleTagsFields';

export const getSampleTagsData = ({ languages, template }) => {
  // inital fields

  const sampleTagsFields = getSampleTagsFields({ languages });

  const ADD_INITIAL_VALUES_SAMPLES_TAGS = {
    templateName: '',
    icon: null,
    optionGeneral: false,
    tagsInner: false,
    tagsAboutHome: false,
    tagsAboutObject: false,
    tagsCommunications: false,
    tagsMap: false,
    tagsRules: false,
    apartments: false,
    house: false,
    commercial: false,
    plot: false,
    sell: false,
    rent: false,
    daily: false,

    ...sampleTagsFields,
  };

  // edit fields

  const sampleTagsFieldsEdit = getSampleTagsFieldsEdit({ languages, template });

  const EDIT_INITIAL_VALUES_SAMPLES_TAGS = {
    id: template?.id,
    templateName: template?.name,
    icon: template?.iconToken
      ? {
          preview:
            template?.iconToken &&
            `${process.env.REACT_APP_API_URL}/uploads/images/${template?.iconToken}`,
        }
      : null,
    iconToken: template?.iconToken,
    optionGeneral: template?.types?.includes(
      fieldsArrayNames.optionGeneralName,
    ),
    tagsInner: template?.types?.includes(fieldsArrayNames.tagsInnerName),
    tagsAboutHome: template?.types?.includes(
      fieldsArrayNames.tagsAboutHomeName,
    ),
    tagsAboutObject: template?.types?.includes(
      fieldsArrayNames.tagsAboutObjectName,
    ),
    tagsCommunications: template?.types?.includes(
      fieldsArrayNames.tagsCommunicationsName,
    ),
    tagsMap: template?.types?.includes(fieldsArrayNames.tagsMapName),
    tagsRules: template?.types?.includes(fieldsArrayNames.tagsRulesName),
    ...sampleTagsFieldsEdit,
    apartments: template?.propertyTypes?.includes(PROPERTY_TYPES.APARTMENTS),
    house: template?.propertyTypes?.includes(PROPERTY_TYPES.HOUSE),
    commercial: template?.propertyTypes?.includes(PROPERTY_TYPES.COMMERCIAL),
    plot: template?.propertyTypes?.includes(PROPERTY_TYPES.PLOT),
    sell: template?.announcementTypes?.includes(OFFER_TYPES.SELL.toLowerCase()),
    rent: template?.announcementTypes?.includes(OFFER_TYPES.RENT.toLowerCase()),
    daily: template?.announcementTypes?.includes(
      OFFER_TYPES.DAILY.toLowerCase(),
    ),
  };

  return {
    ADD_INITIAL_VALUES_SAMPLES_TAGS,
    EDIT_INITIAL_VALUES_SAMPLES_TAGS,
  };
};
