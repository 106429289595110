import { PROPERTY_TYPES } from 'constants';
import {
  INTEGER_NUMBER,
  LINK_FORMAT,
  MAX_LENGTH_NUMBER,
  MIN_LENGTH_NUMBER,
  NUMBER_TO_HUNDREDTHS,
  MIN_LENGTH_PHOTO_NUMBER,
  YEAR_FORMAT,
} from 'validations/messages';
import * as yup from 'yup';

export const addEditAnnouncementSchema = () => {
  return yup.object().shape(
    {
      titleRU: yup.string().required(),
      titleEN: yup.string().required(),
      titleMD: yup.string().required(),
      // aboutHousingRU: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       key: yup.string().required(),
      //       value: yup.string().required(),
      //     }),
      //   )
      //   .min(1, MIN_LENGTH_NUMBER(1))
      //   .max(8, MAX_LENGTH_NUMBER(8)),
      // aboutHousingMD: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       key: yup.string().required(),
      //       value: yup.string().required(),
      //     }),
      //   )
      //   .min(1, MIN_LENGTH_NUMBER(1))
      //   .max(8, MAX_LENGTH_NUMBER(8)),
      // aboutHousingEN: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       key: yup.string().required(),
      //       value: yup.string().required(),
      //     }),
      //   )
      //   .min(1, MIN_LENGTH_NUMBER(1))
      //   .max(8, MAX_LENGTH_NUMBER(8)),
      // aboutTheBuildingRU: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value !== 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }),
      // aboutTheBuildingMD: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value !== 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }),
      // aboutTheBuildingEN: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value !== 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }),
      // aboutAreaRU: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value === 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }),
      // aboutAreaMD: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value === 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }),
      // aboutAreaEN: yup.mixed().when('propertyType', {
      //   is: (propertyType) => propertyType?.value === 'house',
      //   then: yup
      //     .array()
      //     .of(
      //       yup.object().shape({
      //         key: yup.string().required(),
      //         value: yup.string().required(),
      //       }),
      //     )
      //     .min(1, MIN_LENGTH_NUMBER(1))
      //     .max(4, MAX_LENGTH_NUMBER(4)),
      // }), TEMPORARILY COMMENTED SECTIONS FLOW
      price: yup
        .number()
        .positive('')
        .typeError()
        .integer(INTEGER_NUMBER)
        .required(),
      areafull: yup.mixed().when('propertyType', {
        is: (propertyType) => propertyType.value !== PROPERTY_TYPES.PLOT,
        then: yup
          .number()
          .positive('')
          .typeError()
          .integer(INTEGER_NUMBER)
          .required(),
      }),
      landarea: yup.mixed().when('propertyType', {
        is: (propertyType) =>
          propertyType?.value === PROPERTY_TYPES.HOUSE ||
          propertyType.value === PROPERTY_TYPES.PLOT,
        then: yup
          .number()
          .positive('')
          .typeError()
          .test('', NUMBER_TO_HUNDREDTHS(2), (number) =>
            /^\d+(\.\d{1,2})?$/.test(number),
          ),
      }),
      rooms: yup.mixed().when(['isStudio', 'propertyType'], {
        is: (isStudio, propertyType) => {
          return (
            !isStudio &&
            propertyType.value !== PROPERTY_TYPES.COMMERCIAL &&
            propertyType.value !== PROPERTY_TYPES.PLOT
          );
        },
        then: yup
          .number()
          .positive('')
          .typeError()
          .integer(INTEGER_NUMBER)
          .required(),
      }),
      floor: yup.mixed().when('propertyType', {
        is: (propertyType) =>
          propertyType.value !== PROPERTY_TYPES.PLOT ||
          propertyType.value !== PROPERTY_TYPES.HOUSE,
        then: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable(),
      }),
      floorcount: yup.mixed().when('propertyType', {
        is: (propertyType) => propertyType.value !== PROPERTY_TYPES.PLOT,
        then: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable()
          .test((value, context) => {
            return context.parent.floor.value <= value.value;
          }),
      }),
      mapimg: yup.mixed().required(),
      seoimg: yup.mixed().required(),
      photos: yup.array().min(5, MIN_LENGTH_PHOTO_NUMBER(5)),
      link: yup.mixed().when('isAffiliateAnnouncement', {
        is: (isAffiliateAnnouncement) => {
          return !isAffiliateAnnouncement;
        },
        then: yup.string().url(LINK_FORMAT),
      }),
      video: yup.mixed().when('isAffiliateAnnouncement', {
        is: (isAffiliateAnnouncement) => {
          return !isAffiliateAnnouncement;
        },
        then: yup.mixed().required(),
      }),
      districtRU: yup.string().when('district', {
        is: (district) => district?.value === 'other',
        then: yup.string().required(),
      }),
      districtMD: yup.string().when('district', {
        is: (district) => district?.value === 'other',
        then: yup.string().required(),
      }),
      districtEN: yup.string().when('district', {
        is: (district) => district?.value === 'other',
        then: yup.string().required(),
      }),
      cityRU: yup.string().when('city', {
        is: (city) => city?.value === 'other',
        then: yup.string().required(),
      }),
      cityMD: yup.string().when('city', {
        is: (city) => city?.value === 'other',
        then: yup.string().required(),
      }),
      cityEN: yup.string().when('city', {
        is: (city) => city?.value === 'other',
        then: yup.string().required(),
      }),
      agents: yup.object().shape({
        value: yup.number().required(),
        label: yup.string().required(),
      }),
      address: yup.string().when('city', {
        is: (city) => city?.value !== 'chisinau',
        then: yup.string().required(),
      }),
      addressChisinau: yup.mixed().when('city', {
        is: (city) => city?.value === 'chisinau',
        then: yup.mixed().test('', '', (field, schema) => {
          return (
            schema?.from[0]?.value?.city?.value === 'chisinau' && field?.value
          );
        }),
      }),
      houseChisinau: yup.mixed().when('city', {
        is: (city) => city?.value === 'chisinau',
        then: yup.mixed().test('', '', (field, schema) => {
          return (
            schema?.from[0]?.value?.city?.value === 'chisinau' && field?.value
          );
        }),
      }),
      linkMap: yup.mixed().when('isAffiliateAnnouncement', {
        is: (isAffiliateAnnouncement) => {
          return isAffiliateAnnouncement;
        },
        then: yup.string().url(LINK_FORMAT).required(),
      }),
      complexNameRU: yup.mixed().when(['propertyType', 'yearCompleted'], {
        is: (propertyType, yearCompleted) =>
          propertyType.value === PROPERTY_TYPES.APARTMENTS && yearCompleted,
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      complexNameMD: yup.mixed().when(['propertyType', 'yearCompleted'], {
        is: (propertyType, yearCompleted) =>
          propertyType.value === PROPERTY_TYPES.APARTMENTS && yearCompleted,
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      complexNameEN: yup.mixed().when(['propertyType', 'yearCompleted'], {
        is: (propertyType, yearCompleted) =>
          propertyType.value === PROPERTY_TYPES.APARTMENTS && yearCompleted,
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      yearCompleted: yup.mixed().when('propertyType', {
        is: (propertyType) => {
          return propertyType.value === PROPERTY_TYPES.APARTMENTS;
        },
        then: yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value;
          })
          .nullable()
          .positive('')
          .typeError()
          .integer(INTEGER_NUMBER)
          .test('', YEAR_FORMAT, (field) => {
            const yearFormatLength = 4;

            if (field === null || field === undefined) {
              return true;
            }
            return String(field).length === yearFormatLength;
          }),
      }),
    },
    [
      ['yearCompleted', 'complexNameRU'],
      ['yearCompleted', 'complexNameMD'],
      ['yearCompleted', 'complexNameEN'],
    ],
  );
};
