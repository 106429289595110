import { AddIcon } from 'assets';
import Loader from 'components/Loader';
import {
  customFieldsPagination,
  SUCCESS_MESSAGES,
  tableHeadSamplesCustomFieldsItems,
  PATHS,
} from 'constants';
import {
  useDeleteTemplates,
  useGetTemplatesQuery,
  useTemplatesPagination,
} from 'hooks';
import { LoaderWrapper } from 'pages/AddEditAnnouncement/styles';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionsCell,
  AddNewBlock,
  AddNewButton,
  AddText,
  DeleteButton,
  EditButton,
  IdCell,
  ListTitle,
  NameCell,
  NameSectionCell,
  NotFound,
  SamplesCustomFieldsListTable,
  TableBody,
  TableHead,
  TableRow,
  TableWrapper,
  TitleInner,
  TitleText,
  WrapperLoader,
} from './styles';

const SamplesCustomFields = () => {
  const navigate = useNavigate();

  const {
    data: templates,
    isLoading: loadingTemplates,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetTemplatesQuery();

  const { templatesCurrentData } = useTemplatesPagination({
    templates,
    fetchNextPage,
    isRefetching,
    isTemplatesPage: true,
    limit: customFieldsPagination.limit,
  });

  const { mutate: deleteTemplate } = useDeleteTemplates({
    onSuccess() {
      navigate(0); // bad decision refetch, needs to be fixed in the future
      toast.success(SUCCESS_MESSAGES.DELETE_TEMPLATE_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.message, {
        position: 'bottom-left',
      });
    },
  });

  const handleEditCustomFields = (id) => {
    navigate(`${PATHS.editCustomField}/${id}`, {
      state: { isEditMode: true, customFieldId: id },
    });
  };

  const handleDeleteCustomFields = (id) => {
    deleteTemplate({
      id,
    });
  };

  if (loadingTemplates) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }
  return (
    <>
      <ListTitle>
        <TitleInner>
          <TitleText>Шаблоны жилья/здания</TitleText>
        </TitleInner>
      </ListTitle>
      <AddNewBlock>
        <Link to={PATHS.addCustomField}>
          <AddNewButton type="button">
            <AddIcon fill={'#fff'} />
            <AddText>Создать шаблон</AddText>
          </AddNewButton>
        </Link>
      </AddNewBlock>
      {templatesCurrentData?.length ? (
        <TableWrapper>
          <SamplesCustomFieldsListTable>
            <TableBody>
              <TableHead>
                {tableHeadSamplesCustomFieldsItems.map((item) => {
                  return <td key={item.title}>{item.title}</td>;
                })}
              </TableHead>
              {templatesCurrentData?.map((template) => {
                return (
                  <TableRow key={template?.id}>
                    <IdCell>{template?.id}</IdCell>
                    <NameCell>{template?.name}</NameCell>
                    <NameSectionCell>{template?.section?.name}</NameSectionCell>
                    <ActionsCell>
                      <EditButton
                        type="button"
                        onClick={() => handleEditCustomFields(template?.id)}
                      >
                        Редактировать
                      </EditButton>
                      <DeleteButton
                        type="button"
                        onClick={() => handleDeleteCustomFields(template?.id)}
                      >
                        Удалить
                      </DeleteButton>
                    </ActionsCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </SamplesCustomFieldsListTable>
          {isFetching && (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </TableWrapper>
      ) : (
        <NotFound>{'Шаблоны не найдены'}</NotFound>
      )}
    </>
  );
};

export default SamplesCustomFields;
