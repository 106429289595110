export const getCustomFieldsArrays = (sections, languages) => {
  const getSectionId = (name) => {
    let sectionId;
    sections?.map((sec) => {
      if (sec?.alias === name) {
        sectionId = sec?.id;
      }
    });
    return sectionId;
  };

  const customFieldsArrays = sections
    ?.map((item) => item?.alias) // array from sections names for get default object for custom fields
    .reduce((result, item) => {
      languages?.map((lang) => {
        return (result[`${item}${lang?.iso}`] = [
          {
            key: '',
            value: '',
            languageId: lang?.id,
            sectionId: getSectionId(item),
          },
        ]);
      });
      return result;
    }, {});

  return customFieldsArrays;
};

export const getCustomSampleFields = ({ languages }) => {
  const result = {};
  languages?.forEach((lang) => {
    result[`key${lang?.iso}`] = '';
    result[`value${lang?.iso}`] = '';
  });
  return result;
};
