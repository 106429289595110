export const CompassIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.6667C4.318 14.6667 1.33333 11.682 1.33333 8C1.33333 4.318 4.318 1.33333 8 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 11.682 11.682 14.6667 8 14.6667ZM8 13.3333C9.41449 13.3333 10.771 12.7714 11.7712 11.7712C12.7714 10.771 13.3333 9.41449 13.3333 8C13.3333 6.58551 12.7714 5.22896 11.7712 4.22876C10.771 3.22857 9.41449 2.66667 8 2.66667C6.58551 2.66667 5.22896 3.22857 4.22876 4.22876C3.22857 5.22896 2.66667 6.58551 2.66667 8C2.66667 9.41449 3.22857 10.771 4.22876 11.7712C5.22896 12.7714 6.58551 13.3333 8 13.3333V13.3333ZM11 5L9.33333 9.33333L5 11L6.66667 6.66667L11 5ZM8 8.66667C8.17681 8.66667 8.34638 8.59643 8.4714 8.4714C8.59643 8.34638 8.66667 8.17681 8.66667 8C8.66667 7.82319 8.59643 7.65362 8.4714 7.5286C8.34638 7.40357 8.17681 7.33333 8 7.33333C7.82319 7.33333 7.65362 7.40357 7.5286 7.5286C7.40357 7.65362 7.33333 7.82319 7.33333 8C7.33333 8.17681 7.40357 8.34638 7.5286 8.4714C7.65362 8.59643 7.82319 8.66667 8 8.66667V8.66667Z"
        fill="#128555"
      />
    </svg>
  );
};
