import { AddIcon } from 'assets';
import Loader from 'components/Loader';
import {
  BREACKPOINTS,
  SUCCESS_MESSAGES,
  tableHeadAgentItems,
  PATHS,
} from 'constants';
import { useDeleteAgent, useGetAgentsQuery } from 'hooks';
import { useAgentsPagination } from 'hooks/useAgentsPagination';
import { LoaderWrapper } from 'pages/AddEditAnnouncement/styles';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { agentIsUsedError } from 'utils/agentIsUsedError';
import {
  ActionsCell,
  AddNewBlock,
  AddNewButton,
  AddText,
  AgencyCell,
  AgentsListTable,
  DeleteButton,
  EditButton,
  IdCell,
  ListTitle,
  MainImgCell,
  NameCell,
  NotFound,
  PhoneNumberCell,
  TableBody,
  TableHead,
  TableRow,
  TableWrapper,
  TitleInner,
  TitleText,
  WrapperLoader,
} from './styles';

const Agents = () => {
  const navigate = useNavigate();

  const {
    data: agents,
    isLoading: loadingAgents,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetAgentsQuery();

  const { agentsCurrentData } = useAgentsPagination({
    agents,
    fetchNextPage,
    isRefetching,
  });

  const { mutate: deleteAgent } = useDeleteAgent({
    onSuccess() {
      navigate(0); // bad decision refetch, needs to be fixed in the future
      toast.success(SUCCESS_MESSAGES.DELETE_AGENT_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(agentIsUsedError(error), {
        position: 'bottom-left',
      });
    },
  });

  const handleEditAgent = (id) => {
    navigate(`${BREACKPOINTS.editAgent}/${id}`, {
      state: { isEditMode: true, agentId: id },
    });
  };

  const handleDeleteAgent = (id) => {
    deleteAgent({
      id,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingAgents) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <ListTitle>
        <TitleInner>
          <TitleText>Список агентов</TitleText>
        </TitleInner>
      </ListTitle>
      <AddNewBlock>
        <Link to={PATHS.addAgent}>
          <AddNewButton type="button">
            <AddIcon fill={'#fff'} />
            <AddText>Создать агента</AddText>
          </AddNewButton>
        </Link>
      </AddNewBlock>
      {agentsCurrentData?.length ? (
        <TableWrapper>
          <AgentsListTable>
            <TableBody>
              <TableHead>
                {tableHeadAgentItems.map((item) => {
                  return <td key={item.title}>{item.title}</td>;
                })}
              </TableHead>
              {agentsCurrentData?.map((agent) => {
                return (
                  <TableRow key={agent.id}>
                    <IdCell>{agent.id}</IdCell>
                    <MainImgCell>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/images/${agent?.image}`}
                        alt={agent.personName}
                      />
                    </MainImgCell>
                    <NameCell>{agent.personName}</NameCell>
                    <AgencyCell>{agent.agentName}</AgencyCell>
                    <PhoneNumberCell>+{agent.phone}</PhoneNumberCell>
                    <ActionsCell>
                      <EditButton
                        type="button"
                        onClick={() => handleEditAgent(agent.id)}
                      >
                        Редактировать
                      </EditButton>
                      <DeleteButton
                        type="button"
                        onClick={() => handleDeleteAgent(agent.id)}
                      >
                        Удалить
                      </DeleteButton>
                    </ActionsCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </AgentsListTable>
          {isFetching && (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </TableWrapper>
      ) : (
        <NotFound>{'Aгенты не найдены'}</NotFound>
      )}
    </>
  );
};

export default Agents;
