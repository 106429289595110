import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #161e41;
  justify-content: flex-start;
  padding: 16px;
`;

export const CustomLink = styled(Link)`
  color: #161e41;
  opacity: 1;

  font-weight: ${(props) => (props.active ? '700' : '400')};

  :hover {
    opacity: 0.9;
  }
`;

export const BurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 32px;
  cursor: pointer;
`;

export const BurgerLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
`;

export const DropdownList = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  align-items: flex-start;
  color: #161e41;
  gap: 8px;
  font-size: 20px;
  forn-weight: 24px;
  box-shadow: 0px 2px 10px rgba(21, 21, 21, 0.04);
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
