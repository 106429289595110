import { CloseIcon } from 'assets';
import { navigationLinks } from 'constants';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BurgerLine,
  BurgerMenu,
  CloseWrapper,
  CustomLink,
  DropdownList,
  NavigationWrapper,
} from './styles';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <NavigationWrapper>
      <BurgerMenu onClick={toggleMenu}>
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </BurgerMenu>
      {isOpen && (
        <>
          <DropdownList ref={dropdownRef}>
            {navigationLinks.map((link) => {
              return (
                <CustomLink
                  key={link.name}
                  to={link.path}
                  onClick={toggleMenu}
                  active={link.path === location?.pathname}
                >
                  {link.name}
                </CustomLink>
              );
            })}
            <CloseWrapper onClick={toggleMenu}>
              <CloseIcon />
            </CloseWrapper>
          </DropdownList>
        </>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
