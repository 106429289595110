import { useFormContext } from 'react-hook-form';
import { getFieldName } from 'utils';
import {
  CustomTextArea,
  Label,
  TextAreaBlock,
  TextAreaWrapper,
} from './styles';

const TextArea = ({ label, placeholder, name }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  return (
    <TextAreaWrapper label={label}>
      {label && <Label>{label}</Label>}
      <TextAreaBlock>
        <CustomTextArea
          {...register(name)}
          placeholder={placeholder}
          name={name}
          hasError={hasError}
        />
      </TextAreaBlock>
    </TextAreaWrapper>
  );
};

export default TextArea;
