import { getSectionOptions } from 'connectors/sectionsOptions';
import { getCustomSampleFields, getCustomSampleFieldsEdit } from 'utils';

export const getSampleCustomFieldsData = ({
  languages,
  template,
  sections,
}) => {
  // inital fields

  const sampleFields = getCustomSampleFields({ languages });

  const ADD_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS = {
    sectionName: '',
    templateName: '',
    ...sampleFields,
  };

  // edit fields

  const sectionName = getSectionOptions({ sections })?.find(
    (item) => item.value === template?.sectionId,
  );

  const sampleFieldsEdit = getCustomSampleFieldsEdit({ languages, template });

  const EDIT_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS = {
    id: template?.id,
    sectionName: sectionName,
    templateName: template?.name,
    ...sampleFieldsEdit,
  };

  return {
    ADD_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
    EDIT_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
  };
};
