import Loader from 'components/Loader';
import {
  useDeleteSeoMetatags,
  useGetSeoMetatagsQuery,
  useSeoMetatagsPagination,
} from 'hooks';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ActionsCell,
  AddNewBlock,
  AddNewButton,
  AddText,
  AdressCell,
  DeleteButton,
  EditButton,
  IdCell,
  IndexCell,
  ListTitle,
  LoaderWrapper,
  MetatagsListTable,
  NotFound,
  TableBody,
  TableHead,
  TableRow,
  TableWrapper,
  TitleInner,
  TitleText,
  WrapperLoader,
} from './styles';
import { PATHS, tableHeadMetatagsItems, SUCCESS_MESSAGES } from 'constants';
import { AddIcon } from 'assets';
import { toast } from 'react-toastify';
import { IsFavoriteIcon } from 'assets/IsFavoriteIcon';

const Seo = () => {
  const navigate = useNavigate();

  const {
    data: seoMetatags,
    isLoading: loadingSeoMetatags,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetSeoMetatagsQuery();

  const { metaTagsCurrentData } = useSeoMetatagsPagination({
    seoMetatags,
    fetchNextPage,
    isRefetching,
  });

  const { mutate: deleteMetatags } = useDeleteSeoMetatags({
    onSuccess() {
      navigate(0);
      toast.success(SUCCESS_MESSAGES.DELETE_METATAGS_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.error, {
        position: 'bottom-left',
      });
    },
  });

  const handleEditSeoMetatags = (id) => {
    navigate(`${PATHS.editSeoMetatags}/${id}`, {
      state: { isEditMode: true, metatagId: id },
    });
  };

  const handleDeleteMetatags = (id) => {
    deleteMetatags({
      id,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingSeoMetatags) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }
  return (
    <>
      <ListTitle>
        <TitleInner>
          <TitleText>Список страниц</TitleText>
        </TitleInner>
      </ListTitle>
      <AddNewBlock>
        <Link to={PATHS.addSeoMetatags}>
          <AddNewButton type="button">
            <AddIcon fill={'#fff'} />
            <AddText>Создать страницу</AddText>
          </AddNewButton>
        </Link>
      </AddNewBlock>
      {metaTagsCurrentData?.length ? (
        <TableWrapper>
          <MetatagsListTable>
            <TableBody>
              <TableHead>
                {tableHeadMetatagsItems.map((item) => {
                  return <td key={item.title}>{item.title}</td>;
                })}
              </TableHead>
              {metaTagsCurrentData?.map((tag) => {
                return (
                  <TableRow key={tag.id}>
                    <IdCell>{tag.id}</IdCell>
                    <AdressCell>{tag.url}</AdressCell>
                    <IndexCell>{tag.isIndex && <IsFavoriteIcon />}</IndexCell>
                    <ActionsCell>
                      <EditButton
                        type="button"
                        onClick={() => handleEditSeoMetatags(tag.id)}
                      >
                        Редактировать
                      </EditButton>
                      <DeleteButton
                        type="button"
                        onClick={() => handleDeleteMetatags(tag.id)}
                      >
                        Удалить
                      </DeleteButton>
                    </ActionsCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MetatagsListTable>
          {isFetching && (
            <WrapperLoader>
              <Loader />
            </WrapperLoader>
          )}
        </TableWrapper>
      ) : (
        <NotFound>{'Страницы не найдены'}</NotFound>
      )}
    </>
  );
};

export default Seo;
