import {
  AreaIcon,
  CompassIcon,
  DropzoneImgIcon,
  FloorIcon,
  HeatingIcon,
  HomeIcon,
  RoomsIcon,
} from 'assets';

import AboutСustomFields from 'components/AboutСustomFields';
import Checkbox from 'components/Checkbox';
import CustomTagField from 'components/CustomTagField';
import DropzoneFieldRHF from 'components/react-hook-from/DropzoneRHF';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { addressOptionsList } from 'connectors/addressOptionsList';
import { agentsOptions } from 'connectors/agentsOptions';
import { housesOptionsList } from 'connectors/housesOptionsList';
import {
  landAreaTypeOptions,
  districtsOptions,
  citiesOptions,
  typeOfPropertyOptions,
  typePropertyOptions,
  typeOfferOptions,
  floorCount,
  mdCityOtherOptions,
  typeOfCommercialOptions,
  PROPERTY_TYPES,
  OFFER_TYPES,
  appointmentOptions,
  buildingTypeOptions,
  heatingOptions,
  advantagesOptions,
  filteredAdvantagesOptions,
  renovationOptions,
  houseTypeOptions,
  readinessOptions,
} from 'constants';
import { getFilteredSections } from 'hooks';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AdvantageCheckboxBlock,
  ContentInnerBlock,
  ContentWrapperBlock,
  FloorBox,
  FloorContainer,
  MainCheckboxBlock,
  TabWrapper,
  TitleText,
} from './styles';
import TextArea from 'components/TextArea';

const Tab = ({
  sections,
  lang,
  langId,
  agents,
  address,
  templates,
  languages,
}) => {
  const { watch, setValue } = useFormContext();
  const announcementType = watch('announcementType');
  const propertyType = watch('propertyType');
  const otherCityOption = watch('city');
  const otherDistrictOption = watch('district');
  const optionsAgents = agentsOptions({ agents });
  const addressChisinau = watch('addressChisinau');
  const studio = watch('isStudio');
  const appointmentType = watch('appointment');
  const addressOptions = addressOptionsList({ address });
  const housesOptions = housesOptionsList({ address, addressChisinau });
  const { filteredSection } = getFilteredSections({ propertyType, sections });
  const isApartmentsType = propertyType?.value === PROPERTY_TYPES.APARTMENTS;
  const isHouseType = propertyType?.value === PROPERTY_TYPES.HOUSE;
  const isAffiliateAnnouncement = watch('isAffiliateAnnouncement');
  const isCommercialType = propertyType?.value === PROPERTY_TYPES.COMMERCIAL;
  const isDailyType = announcementType?.value === OFFER_TYPES.DAILY;
  const isPlotType = propertyType?.value === PROPERTY_TYPES.PLOT;

  const typePropertyOptionsData = typePropertyOptions.filter((option) => {
    switch (announcementType?.value) {
      case OFFER_TYPES.DAILY:
        return (
          option.value !== PROPERTY_TYPES.COMMERCIAL &&
          option.value !== PROPERTY_TYPES.PLOT
        );
      case OFFER_TYPES.RENT:
        return option.value !== PROPERTY_TYPES.PLOT;
      default:
        return option;
    }
  });

  const appointmentOptionsData = isPlotType
    ? appointmentOptions.slice(-2)
    : appointmentOptions.slice(0, -2);

  const getAdvantagesOptions = () => {
    return advantagesOptions?.filter((item) =>
      filteredAdvantagesOptions[propertyType?.value]?.includes(item.value),
    );
  };

  const advantageOptionsArr = getAdvantagesOptions();

  const handleCityChange = () => {
    setValue('addressChisinau', '');
    setValue('houseChisinau', '');
    setValue('address', '');
  };

  const handleAddressChisinauChange = () => {
    setValue('houseChisinau', '');
  };

  useEffect(() => {
    const isIncludePropertyType = typePropertyOptionsData.some(
      (item) => item.value === propertyType?.value,
    );

    if (!isIncludePropertyType) {
      setValue('propertyType', typePropertyOptionsData[0]);
    }
  }, [announcementType?.value]);

  useEffect(() => {
    const isIncludeAppointmentType = appointmentOptionsData.some((item) =>
      appointmentType?.label.includes(item.label),
    );

    if (!isIncludeAppointmentType) {
      setValue('appointment', appointmentOptionsData[0]);
    }
  }, [propertyType?.value]);

  useEffect(() => {
    if (otherCityOption?.value !== 'chisinau') {
      setValue(
        'district',
        districtsOptions.find((item) => item.value === 'other'),
      );
    }
    if (otherCityOption?.value === 'other') {
      setValue('isOtherCity', true);
    } else {
      setValue('isOtherCity', false);
    }
  }, [otherCityOption]);

  return (
    <TabWrapper>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <MainCheckboxBlock>
            <Checkbox name="isFavorite" label="Отметить как избранное" isTop />
            <Checkbox name="isNotActive" label="Не активно" isTop />
            <Checkbox name="isAdvertisement" label="Рекламируется" isTop />
            <Checkbox
              name="isAffiliateAnnouncement"
              label="От партнеров"
              isTop
            />
          </MainCheckboxBlock>
          <TitleText>Информация</TitleText>
          <Select
            name="announcementType"
            label={'Тип предложения *'}
            options={typeOfferOptions}
          />
          <Select
            name="propertyType"
            label={'Тип недвижимости *'}
            options={typePropertyOptionsData}
          />
          {isCommercialType && (
            <Select
              name="commercialType"
              label={'Вид коммерческой недвижимости *'}
              options={typeOfCommercialOptions}
            />
          )}
          <Select
            name="city"
            label={'Город *'}
            options={citiesOptions}
            onInputChange={handleCityChange}
          />
          {otherCityOption?.value === 'mdcityother' && (
            <Select
              name="mdcityother"
              label={'Другие населенные пункты *'}
              options={mdCityOtherOptions}
            />
          )}
          {otherCityOption?.value === 'other' && (
            <TextField
              name={`city${lang}`}
              label={'Город (свободная пропись) *'}
              placeholder={'Введите город'}
              type="text"
            />
          )}
          <TextField
            name={`title${lang}`}
            label={'Заголовок *'}
            placeholder={'Введите заголовок'}
            type="text"
          />
          <TextField
            name="price"
            label={'Цена *'}
            placeholder={'0'}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          {otherCityOption?.value === 'chisinau' ? (
            <Select
              name={'addressChisinau'}
              label={'Адрес *'}
              placeholder={'Введите улицу или выберите из списка'}
              options={addressOptions}
              isSearchable
              isClearable
              onInputChange={handleAddressChisinauChange}
            />
          ) : (
            <TextField
              name={`address`}
              label={'Адрес *'}
              placeholder={'Введите заголовок'}
              type="text"
            />
          )}
          {otherCityOption?.value === 'chisinau' && addressChisinau?.value && (
            <>
              <Select
                name={'houseChisinau'}
                label={'Номер дома *'}
                placeholder={'Введите номер дома или выберите из списка'}
                options={housesOptions}
                isSearchable
                isClearable
              />
              <Checkbox name="showAddress" label="Показывать номер дома" />
            </>
          )}
          {isApartmentsType && (
            <>
              <TextField
                name={`complexName${lang}`}
                label={'Название ЖК'}
                placeholder={'Введите название ЖК'}
                type="text"
              />
              <TextField
                name={'yearCompleted'}
                label={'Год сдачи дома'}
                placeholder={'Введите год сдачи'}
                type="number"
              />
              <Select
                name="readinessType"
                label={'Готовность объекта *'}
                options={readinessOptions}
              />
            </>
          )}
          <TextArea
            name={`description${lang}`}
            label={'Описание'}
            placeholder={'Введите описание'}
          />
          <ContentWrapperBlock elemGroup>
            <ContentInnerBlock>
              <CustomTagField
                name={`optionGeneral${lang}`}
                templateName="optionGeneral"
                inputName="general"
                languages={languages}
                title="Опции основного блока"
                customTag
                elemGroup
                announcementType={announcementType?.value}
                propertyType={propertyType?.value}
              />
            </ContentInnerBlock>
          </ContentWrapperBlock>
        </ContentInnerBlock>
      </ContentWrapperBlock>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Ссылки и фото</TitleText>
          {!isAffiliateAnnouncement && (
            <TextField
              name="link"
              label={'Ссылка на 3D тур'}
              placeholder={'https://'}
              type="text"
            />
          )}
          <TextField
            name="linkMap"
            label={'Ссылка на Google карту'}
            placeholder={'https://'}
            type="text"
          />
          <DropzoneFieldRHF
            name="mapimg"
            dropText={'Добавить фото Карты *'}
            dropBg={'#e2ffe7'}
            imgBg={<DropzoneImgIcon />}
            single
          />
          {!isAffiliateAnnouncement && (
            <>
              <DropzoneFieldRHF
                name="video"
                dropText={'Добавить видео *'}
                dropBg={'#e2ffe7'}
                imgBg={<DropzoneImgIcon />}
                isVideo
              />
            </>
          )}
          <DropzoneFieldRHF
            name="photos"
            dropText={'Добавить фото *'}
            imgBg={<DropzoneImgIcon />}
            multiple
          />
          <DropzoneFieldRHF
            name="seoimg"
            dropText={'Добавить фото для SEO *'}
            dropBg={'#e2ffe7'}
            imgBg={<DropzoneImgIcon />}
            single
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Статическая информация</TitleText>
          {!isPlotType && (
            <TextField
              name="areafull"
              label={'Площадь общая м2 *'}
              placeholder={'0'}
              leftIcon={<AreaIcon />}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
            />
          )}
          {((!studio && !isPlotType && !isCommercialType) || isHouseType) && (
            <TextField
              name="rooms"
              label={'Кол-во комнат *'}
              placeholder={'0'}
              leftIcon={<RoomsIcon />}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
            />
          )}
          {propertyType?.value === 'apartments' && (
            <Checkbox name="isStudio" label="Студия" />
          )}
          {!isPlotType && (
            <FloorContainer>
              {!isHouseType && (
                <FloorBox>
                  <Select
                    name="floor"
                    label={'Этаж квартиры *'}
                    options={floorCount}
                    leftIcon={<FloorIcon />}
                  />
                </FloorBox>
              )}
              <Select
                name="floorcount"
                label={'Кол-во этажей *'}
                options={floorCount}
                leftIcon={<FloorIcon />}
              />
            </FloorContainer>
          )}
          {!isHouseType && !isPlotType ? (
            !isCommercialType && (
              <Select
                name={'typeOfProperty'}
                label={'Вид собственности *'}
                options={typeOfPropertyOptions}
                leftIcon={<HomeIcon />}
              />
            )
          ) : (
            <>
              <TextField
                name="landarea"
                label={'Площадь участка *'}
                placeholder={'0'}
                leftIcon={<HomeIcon />}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
              />
              <Select
                name={'landareaType'}
                label={'Единицы измерения *'}
                options={landAreaTypeOptions}
                leftIcon={<HomeIcon />}
              />
            </>
          )}
          {otherCityOption?.value === 'chisinau' && (
            <Select
              name="district"
              label={'Район *'}
              options={districtsOptions}
              leftIcon={<CompassIcon />}
            />
          )}
          {(otherDistrictOption?.value === 'other' ||
            otherCityOption?.value !== 'chisinau') && (
            <TextField
              name={`district${lang}`}
              label={'Район (свободная пропись) *'}
              placeholder={'Введите район'}
              leftIcon={<CompassIcon />}
              type="text"
            />
          )}
          {!isCommercialType && !isPlotType && (
            <Select
              name="heating"
              label={'Отопление *'}
              options={heatingOptions}
              leftIcon={<HeatingIcon />}
            />
          )}
          {(isCommercialType || isPlotType) && (
            <Select
              name="appointment"
              label={'Назначение *'}
              options={appointmentOptionsData}
              leftIcon={<HomeIcon />}
            />
          )}
          {isCommercialType && (
            <Select
              name="buildingType"
              label={'Тип здания *'}
              options={buildingTypeOptions}
              leftIcon={<HomeIcon />}
            />
          )}
          {(isApartmentsType || isHouseType) && (
            <Select
              name="apartmentRenovation"
              label={'Ремонт *'}
              options={renovationOptions}
              leftIcon={<HomeIcon />}
            />
          )}
          {isHouseType && (
            <Select
              name="houseType"
              label={'Тип дома *'}
              options={houseTypeOptions}
              leftIcon={<HomeIcon />}
            />
          )}
        </ContentInnerBlock>
      </ContentWrapperBlock>
      {/* {filteredSection?.map((section) => {
        return (
          <ContentWrapperBlock key={section?.id}>
            <ContentInnerBlock>
              <TitleText>{section?.name}</TitleText>
              <AboutСustomFields
                name={`${section?.alias}${lang}`}
                langId={langId}
                sectionId={section?.id}
                lang={lang}
                templates={templates}
                sectionName={section?.alias}
                
              />
            </ContentInnerBlock>
          </ContentWrapperBlock>
        );
      })}
      <SamplesCustomFieldsModal
        modalIsOpen={modalSamplesCustomFieldsOpen}
        closeModal={handleCloseSamplesCustomFields}
        sectionName={modalData}
      />  TEMPORARILY COMMENTED SECTIONS FLOW  */}
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Преимущества</TitleText>
          <AdvantageCheckboxBlock>
            {advantageOptionsArr?.map((option) => {
              return (
                <Checkbox
                  name={option.value}
                  label={option.label}
                  key={option.value}
                />
              );
            })}
          </AdvantageCheckboxBlock>
        </ContentInnerBlock>
      </ContentWrapperBlock>
      {!isCommercialType && !isPlotType && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsInner${lang}`}
              templateName="tagsInner"
              inputName="inner"
              title="Что находится внутри"
              languages={languages}
              customTag
              announcementType={announcementType?.value}
              propertyType={propertyType?.value}
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      {!isCommercialType && !isPlotType && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsAboutHome${lang}`}
              templateName="tagsAboutHome"
              inputName="aboutHome"
              title="Информация о доме"
              languages={languages}
              customTag
              announcementType={announcementType?.value}
              propertyType={propertyType?.value}
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      {(isCommercialType || isPlotType) && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsAboutObject${lang}`}
              templateName="tagsAboutObject"
              inputName="aboutObject"
              title="Информация об объекте"
              languages={languages}
              customTag
              announcementType={announcementType?.value}
              propertyType={propertyType?.value}
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      {(isHouseType || isPlotType) && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsCommunications${lang}`}
              templateName="tagsCommunications"
              inputName="communications"
              title="Коммуникации"
              languages={languages}
              customTag
              announcementType={announcementType?.value}
              propertyType={propertyType?.value}
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <CustomTagField
            name={`tagsMap${lang}`}
            templateName="tagsMap"
            inputName="map"
            title="Места вокруг"
            languages={languages}
            customTag
            announcementType={announcementType?.value}
            propertyType={propertyType?.value}
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      {(announcementType?.value === 'RENT' || isDailyType) && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsRules${lang}`}
              templateName="tagsRules"
              inputName="rules"
              title="Правила в доме"
              languages={languages}
              customTag
              announcementType={announcementType?.value}
              propertyType={propertyType?.value}
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Агент</TitleText>
          <Select
            name="agents"
            label={'Выбор агента *'}
            options={optionsAgents}
            placeholder={'Выберите агента'}
            isSearchable
            isClearable
            menuOnTop
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
    </TabWrapper>
  );
};

export default Tab;
