import * as yup from 'yup';

export const addEditSampleCustomFields = () => {
  return yup.object().shape({
    sectionName: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    templateName: yup.string().required(),
    keyRU: yup.string().required(),
    keyMD: yup.string().required(),
    keyEN: yup.string().required(),
    valueRU: yup.string().required(),
    valueMD: yup.string().required(),
    valueEN: yup.string().required(),
  });
};
