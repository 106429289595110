import * as yup from 'yup';

export const addEditSampleTags = () => {
  return yup.object().shape({
    templateName: yup.string().required(),
    valueRU: yup.string().required(),
    valueMD: yup.string().required(),
    valueEN: yup.string().required(),
  });
};
