import styled from 'styled-components';

export const CustomTextField = styled.input`
  display: flex;
  padding: ${(props) =>
    props.rightIcon
      ? '16px 34px 16px 12px'
      : props.leftIcon
      ? '16px 12px 16px 34px'
      : '16px 12px'};
  width: 100%;
  height: ${(props) => (props.customTag ? '42px' : '52px')};
  border: ${(props) =>
    props.hasError ? '1px solid red' : '1px solid #dbdfe8'};
  border-radius: 6px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #161e41;

  :focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #dbdfe8;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const Label = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #9b9ea7;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${(props) => (props.customTag ? 'auto' : '76px')};
  margin-top: 16px;
`;

export const TextFieldBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 52px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const RightIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: ${(props) => (props.customTag ? '12px' : '16px')};
`;

export const OrderTop = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const OrderBottom = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const LeftIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 13px;
  top: 18px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: red;
`;
