import styled from 'styled-components';

export const PhoneInputWrapper = styled.div`
  height: 76px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }

  .form-control {
    height: 52px !important;
    width: 100% !important;
    border-color: ${(props) =>
      props.hasError ? 'red !important' : '#dbdfe8 !important'};
    border-radius: 6px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #161e41;
    font-family: 'Jost';
    font-style: normal;

    ::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #dbdfe8;
    }
  }
  .flag-dropdown {
    border-radius: 6px 0 0 6px !important;
    border-color: ${(props) =>
      props.hasError ? 'red !important' : '#dbdfe8 !important'};
    border-right: none !important;
  }
  .selected-flag {
    border-radius: 6px 0 0 6px !important;
    border-color: ${(props) =>
      props.hasError ? 'red !important' : '#dbdfe8 !important'};
    background: #fff !important;
  }
  .country-list {
    user-select: none;
  }
`;

export const Label = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #9b9ea7;
  margin-bottom: 8px;
`;
