import styled from 'styled-components';

export const CustomLoader = styled.div`
  width: ${(props) => (props.width ? props.width : '48px')};
  height: ${(props) => (props.height ? props.height : '48px')};
  border: ${(props) =>
    props.fill ? `5px solid ${props.fill}` : '5px solid #161e41'};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
