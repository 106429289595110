import { metaTagsPagination } from 'constants';
import { useEffect, useState } from 'react';

export const useSeoMetatagsPagination = ({ seoMetatags, fetchNextPage }) => {
  const [offset, setOffset] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const paginatedAgentsData = () => {
    let result = [];
    seoMetatags?.pages?.forEach((item) => {
      result.push(...item.rows);
    });
    return result;
  };

  const metaTagsCurrentData = paginatedAgentsData();

  const isMoreMetaTags =
    seoMetatags?.pages[0]?.count > metaTagsCurrentData.length;

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      isMoreMetaTags
    ) {
      setIsDataLoading(true);
    }
  };

  useEffect(() => {
    if (isDataLoading && isMoreMetaTags) {
      setOffset((prev) => {
        return prev + metaTagsPagination.limit;
      });
    }
  }, [isDataLoading]);

  useEffect(() => {
    offset > 0 &&
      fetchNextPage({
        pageParam: {
          limit: metaTagsPagination.limit,
          offset: offset,
        },
      }).then(() => {
        setIsDataLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    if (isMoreMetaTags) {
      document.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [seoMetatags?.pages[0]?.count, metaTagsCurrentData, isMoreMetaTags]);

  return { metaTagsCurrentData };
};
