import styled from 'styled-components';

export const CustomTagFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const CustomTagFieldOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 28px;
  background: #161e41;
  border-radius: 6px;
  align-items: center;
`;

export const OptionsList = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

export const OptionText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-right: 12px;
`;

export const OptionRemove = styled.div`
  cursor: pointer;
  display: flex;
`;

export const AddMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  height: 42px;
  background: #161e41;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  opacity: 1;
  margin-top: 16px;

  :hover {
    opacity: 0.9;
  }
`;

export const AddText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 8px;
`;

export const TitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: ${(props) =>
    props.elemGroup ? '12px' : props.customTag ? '20px' : '14px'};
  line-height: ${(props) =>
    props.elemGroup ? '16px' : props.customTag ? '26px' : '18px'};
  letter-spacing: 0.02em;
  color: ${(props) => (props.elemGroup ? '#9b9ea7' : '#000000')};
  justify-content: space-between;
  align-items: flex-end;
`;

export const AddWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 15px;
`;
