import TextField from 'components/TextField';
import { ContentInnerBlock, ContentWrapperBlock, TabWrapper } from '../styles';
import Checkbox from 'components/Checkbox';
import EditorComponent from 'components/Editor';

const SeoMetatagsTab = ({ lang }) => {
  return (
    <TabWrapper>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <Checkbox name="isIndex" label="Индексация вкл." />
          <TextField
            name="url"
            label={'Ссылка на страницу'}
            placeholder={'/catalog'}
            type="text"
          />
          <TextField
            name={`title${lang}`}
            label={'Заголовок(title)'}
            placeholder={'Введите заголовок(title)'}
            type="text"
          />
          <TextField
            name={`description${lang}`}
            label={'Описание(description)'}
            placeholder={'Введите описание(description)'}
            type="text"
          />
          <TextField
            name={`h1${lang}`}
            label={'H1'}
            placeholder={'Введите H1'}
            type="text"
          />
          <EditorComponent name={`text${lang}`} label={'Сео текст'} />
        </ContentInnerBlock>
      </ContentWrapperBlock>
    </TabWrapper>
  );
};

export default SeoMetatagsTab;
