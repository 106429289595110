import { getAnnouncementById } from 'api';
import { useQuery } from 'react-query';

export const useGetAnnouncementByIdQuery = (options) => {
  return useQuery(
    ['announcementById', options.id],
    () => getAnnouncementById(options),
    {
      refetchOnWindowFocus: false,
    },
  );
};
