import Dropzone from 'components/Dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { getFieldName } from 'utils';

const DropzoneFieldRHF = ({ name, ...rest }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        const handleChange = (file) => {
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
          }, 0);
        };
        return (
          <Dropzone
            value={getValues(name)}
            onChange={handleChange}
            {...rest}
            hasError={getFieldName(errors, name)}
          />
        );
      }}
    />
  );
};

export default DropzoneFieldRHF;
