import styled from 'styled-components';

export const ListTitle = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background: #fff;
  justify-content: center;
  padding: 0 16px;
  margin-bottom: 2px;
  box-shadow: 0px 2px 10px rgba(21, 21, 21, 0.04);
`;

export const TitleInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff;
`;

export const TitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`;

export const AddNewBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;

  a {
    width: 100%;
    max-width: 343px;
    @media (max-width: 768px) {
      max-width: initial;
    }
  }
`;

export const AddNewButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  max-width: 343px;
  height: 52px;
  background: #161e41;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    max-width: initial;
  }
`;

export const AddText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 8px;
`;

export const TableWrapper = styled.div`
  overflow-x: scroll;
`;

export const AnnouncementsListTable = styled.table`
  width: 100%;
  padding: 16px;
  border-spacing: initial;
  min-width: 768px;
`;

export const TableHead = styled.tr`
  td {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #fff;
    background: #161e41;
    text-align: center;
    padding: 8px;
  }
`;

export const TableRow = styled.tr`
  td {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #000;
    background: #fff;
    text-align: center;
    padding: 8px;
    border-top: 1px solid #dbdfe8;
  }
`;

export const EditButton = styled.button`
  padding: 12px 6px;
  background: #161e41;
  border-radius: 6px;
  border: none;
  color: #fff;
  width: 100px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

export const DeleteButton = styled.button`
  padding: 12px 6px;
  background: #161e41;
  border-radius: 6px;
  margin-left: 8px;
  border: none;
  color: #fff;
  width: 100px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

export const TableBody = styled.tbody``;
export const IdCell = styled.td`
  a {
    color: #000;
  }
`;
export const AnnouncementTypeCell = styled.td``;
export const FavoriteCell = styled.td``;
export const TitleCell = styled.td``;
export const PriceCell = styled.td``;
export const AreaTotalCell = styled.td``;

export const ActionsCell = styled.td`
  white-space: nowrap;
`;

export const MainImgCell = styled.td`
  img {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    object-fit: cover;
  }
`;

export const WrapperLoader = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

export const NotFound = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
