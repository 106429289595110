export const TelegramIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7198 4.60447L19.4658 19.9499C19.2204 21.033 18.5801 21.3025 17.6703 20.7924L12.7125 17.1388L10.3203 19.4398C10.0556 19.7045 9.83412 19.9259 9.32388 19.9259L9.68004 14.8765L18.869 6.57317C19.2685 6.21702 18.7824 6.01963 18.248 6.37583L6.88826 13.5287L1.99774 11.998C0.933959 11.6659 0.91474 10.9342 2.21913 10.424L21.3479 3.05455C22.2335 2.72244 23.0086 3.25184 22.7198 4.60447Z"
        fill="#20A0E1"
      />
    </svg>
  );
};
