import styled from 'styled-components';

export const ContentWrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${(props) => (props.elemGroup ? '26px 0 0' : '26px 0')};
  border-bottom: 1px solid #dbdfe8;
  :last-child {
    border-bottom: none;
  }

  @media (max-width: 816px) {
    padding: ${(props) => (props.elemGroup ? '16px 0 0' : '16px')};
  }
`;

export const ContentInnerBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 810px;
`;

export const TitleText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: ${(props) =>
    props.elemGroup ? '12px' : props.customTag ? '20px' : '14px'};
  line-height: ${(props) =>
    props.elemGroup ? '16px' : props.customTag ? '26px' : '18px'};
  letter-spacing: 0.02em;
  color: ${(props) => (props.elemGroup ? '#9b9ea7' : '#000000')};
  justify-content: space-between;
  align-items: flex-end;
`;

export const FloorContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SubTitleText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #9b9ea7;
`;

export const FloorBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 369px;
  margin-right: 16px;
`;

export const TabWrapper = styled.div`
  width: 100%;
`;

export const MainCheckboxBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AdvantageCheckboxBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
