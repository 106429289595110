import { AddIcon, CloseIcon } from 'assets';
import { useModalContext } from 'context/modal/modal.provider';

import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  AddMoreButton,
  AddText,
  AddWrapper,
  CustomTagFieldOption,
  CustomTagFieldWrapper,
  OptionRemove,
  OptionsList,
  OptionText,
  TitleText,
} from './styles';
import { MODAL_NAME } from 'constants';

const CustomTagField = ({
  name,
  inputName,
  templateName,
  customTag,
  elemGroup,
  title,
  languages,
  announcementType,
  propertyType,
}) => {
  const { control, getValues, setValue } = useFormContext();
  const { fields } = useFieldArray({ control, name: name });
  const { onOpenModal } = useModalContext();

  const handleDeleteOption = (index) => {
    languages.map((lang) => {
      let currentValue = getValues(`${templateName}${lang.iso}`);
      currentValue?.splice(index, 1);
      setValue(`${templateName}${lang.iso}`, currentValue);
    });
  };

  const currentTemplateIds = fields.map((field) => field.tagTemplateId);

  const handleOpenSamplesTagModal = () => {
    onOpenModal(MODAL_NAME.SAMPLES_TAGS, {
      modalData: {
        inputName,
        templateName,
        currentTemplateIds,
        announcementType,
        propertyType,
      },
      formValue: {
        getValues,
        setValue,
      },
    });
  };

  return (
    <CustomTagFieldWrapper>
      <TitleText customTag={customTag} elemGroup={elemGroup}>
        {title}
      </TitleText>
      <AddWrapper>
        <AddMoreButton onClick={handleOpenSamplesTagModal} type="button">
          <AddIcon fill={'#fff'} />
          <AddText>Выбрать из шаблона</AddText>
        </AddMoreButton>
      </AddWrapper>
      <OptionsList>
        {fields.map((field, index) => {
          return (
            <CustomTagFieldOption key={index}>
              <OptionText>{field.name}</OptionText>
              <OptionRemove onClick={() => handleDeleteOption(index)}>
                <CloseIcon width={16} height={16} fill={'#fff'} />
              </OptionRemove>
            </CustomTagFieldOption>
          );
        })}
      </OptionsList>
    </CustomTagFieldWrapper>
  );
};

export default CustomTagField;
