import styled from 'styled-components';

export const AboutWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const AddMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  height: 52px;
  background: #161e41;
  border-radius: 8px;
  margin-top: 32px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${(props) => (props.empty ? '1px solid red' : 'none')};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};

  :hover {
    opacity: ${(props) => (props.disabled ? '0.7' : '0.9')};
  }

  @media (max-width: 768px) {
    max-width: initial;
  }
`;

export const AddText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 8px;
`;

export const AboutFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 374px;
  margin-right: 26px;

  @media (max-width: 768px) {
    margin-right: 7px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;

export const MaxLengthError = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: red;
  margin-top: 16px;
`;
