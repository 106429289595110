import { useCallback, useContext, useEffect, useState } from 'react';
import ModalContext from './modal.context';
import { MODAL_NAME } from 'constants';
import SamplesTagsModal from 'components/Modals/SamplesTagsModal';

const ModalProvider = ({ children }) => {
  const [infoModal, setInfoModal] = useState({
    name: MODAL_NAME.NO_MODAL,
    open: false,
    content: {},
  });

  const onOpenModal = (name, content = {}) => {
    setInfoModal({ name, content, open: true });
  };

  const onCloseModal = useCallback((content = {}) => {
    setInfoModal({
      name: MODAL_NAME.NO_MODAL,
      content: content || {},
      open: false,
    });
  }, []);

  useEffect(() => {
    if (infoModal.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [infoModal.open]);

  useEffect(() => {
    window.addEventListener('popstate', onCloseModal);
    return () => {
      window.removeEventListener('popstate', onCloseModal);
    };
  }, [onCloseModal]);

  const value = {
    infoModal,
    onOpenModal,
    onCloseModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ActiveModal />
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return modalContext;
};

const ActiveModal = () => {
  const { infoModal } = useModalContext();

  switch (infoModal.name) {
    case MODAL_NAME.SAMPLES_TAGS:
      return <SamplesTagsModal />;
    default:
      return <div></div>;
  }
};
