import { MODAL_NAME } from 'constants';
import { createContext } from 'react';

const defaultValue = {
  infoModal: {
    name: MODAL_NAME.NO_MODAL,
    content: {},
    open: false,
  },
  onCloseModal: () => {},
  onOpenModal: () => {},
};

export default createContext(defaultValue);
