import { statisticPagination } from 'constants';
import { useEffect, useState } from 'react';

export const useStatisticPagination = ({ statistic, fetchNextPage }) => {
  const [offset, setOffset] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const paginatedStatisticData = () => {
    let result = [];
    statistic?.pages?.forEach((item) => {
      result.push(...item.rows);
    });
    return result;
  };

  const statisticCurrentData = paginatedStatisticData();

  const isMoreStatistic =
    statistic?.pages[0]?.count > statisticCurrentData.length;

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      isMoreStatistic
    ) {
      setIsDataLoading(true);
    }
  };

  useEffect(() => {
    if (isDataLoading && isMoreStatistic) {
      setOffset((prev) => {
        return prev + statisticPagination.limit;
      });
    }
  }, [isDataLoading]);

  useEffect(() => {
    offset > 0 &&
      fetchNextPage({
        pageParam: {
          limit: statisticPagination.limit,
          offset: offset,
        },
      }).then(() => {
        setIsDataLoading(false);
      });
  }, [offset]);

  useEffect(() => {
    if (isMoreStatistic) {
      document.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [statistic?.pages[0]?.count, statisticCurrentData, isMoreStatistic]);

  return { statisticCurrentData };
};
