export const FloorIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60784 12.3922H6.84706V9.95817H8.73203V7.52418H10.617V5.0719H12.3922V3.60784L9.15294 3.60784V6.04183H7.26797V8.47582H5.38301V10.9281H3.60784V12.3922ZM2.87582 14.5882C2.4732 14.5882 2.12842 14.445 1.84146 14.1585C1.555 13.8716 1.41176 13.5268 1.41176 13.1242L1.41176 2.87582C1.41176 2.4732 1.555 2.12842 1.84146 1.84146C2.12842 1.555 2.4732 1.41176 2.87582 1.41176L13.1242 1.41176C13.5268 1.41176 13.8716 1.555 14.1585 1.84146C14.445 2.12842 14.5882 2.4732 14.5882 2.87582V13.1242C14.5882 13.5268 14.445 13.8716 14.1585 14.1585C13.8716 14.445 13.5268 14.5882 13.1242 14.5882H2.87582ZM2.87582 13.1242H13.1242V2.87582H2.87582V13.1242ZM2.87582 2.87582V13.1242V2.87582Z"
        fill="#128555"
      />
    </svg>
  );
};
