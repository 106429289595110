import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeleteIcon } from 'assets';

import { DeleteBoxMultiple, MultipleImageBlock, Wrapper } from './styles';

const MultipleImageComponent = ({ item, deletePreviewImage }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id: item });

  const stylesDndBlock = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '10' : 'auto',
    opacity: isDragging ? 0.6 : 1,
  };

  return (
    <Wrapper ref={setNodeRef} style={stylesDndBlock}>
      <MultipleImageBlock
        src={item.preview}
        alt="i"
        {...listeners}
        {...attributes}
      />
      <DeleteBoxMultiple
        onClick={() => {
          deletePreviewImage(item);
        }}
      >
        <DeleteIcon />
      </DeleteBoxMultiple>
    </Wrapper>
  );
};

export default MultipleImageComponent;
