import { useFormContext } from 'react-hook-form';
import { getFieldName } from 'utils';
import {
  CustomTextField,
  ErrorMessage,
  Label,
  LeftIconWrapper,
  RightIconWrapper,
  TextFieldBlock,
  TextFieldWrapper,
} from './styles';

const TextField = ({
  label,
  placeholder,
  name,
  customTag,
  type = 'text',
  rightIcon,
  leftIcon,
  handleRightIcon,
  handleLeftIcon,
  step = '1',
  inputMode,
  pattern,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  return (
    <TextFieldWrapper label={label} customTag={customTag}>
      {label && <Label>{label}</Label>}
      <TextFieldBlock>
        {leftIcon && (
          <LeftIconWrapper customTag={customTag} onClick={handleLeftIcon}>
            {leftIcon}
          </LeftIconWrapper>
        )}
        <CustomTextField
          {...register(name)}
          placeholder={placeholder}
          name={name}
          customTag={customTag}
          rightIcon={rightIcon}
          leftIcon={leftIcon}
          type={type}
          hasError={hasError}
          onWheel={(e) => e.target.blur()}
          step={step}
          min="0"
          inputMode={inputMode}
          pattern={pattern}
        />
        {rightIcon && (
          <RightIconWrapper customTag={customTag} onClick={handleRightIcon}>
            {rightIcon}
          </RightIconWrapper>
        )}
      </TextFieldBlock>
      {(type === 'number' || name === 'link') && (
        <ErrorMessage>{hasError?.message}</ErrorMessage>
      )}
    </TextFieldWrapper>
  );
};

export default TextField;
