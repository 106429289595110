export const AreaIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 4.66667V2C4.66667 1.82319 4.7369 1.65362 4.86193 1.5286C4.98695 1.40357 5.15652 1.33333 5.33333 1.33333L14 1.33333C14.1768 1.33333 14.3464 1.40357 14.4714 1.5286C14.5964 1.65362 14.6667 1.82319 14.6667 2V10.6667C14.6667 10.8435 14.5964 11.013 14.4714 11.1381C14.3464 11.2631 14.1768 11.3333 14 11.3333H11.3333V13.9953C11.3333 14.366 11.034 14.6667 10.662 14.6667H2.00467C1.91648 14.6668 1.82914 14.6495 1.74765 14.6157C1.66617 14.582 1.59212 14.5326 1.52977 14.4702C1.46741 14.4079 1.41796 14.3338 1.38426 14.2523C1.35055 14.1709 1.33325 14.0835 1.33333 13.9953L1.33533 5.338C1.33533 4.96733 1.63467 4.66667 2.00667 4.66667H4.66667ZM6 4.66667H10.662C11.0327 4.66667 11.3333 4.966 11.3333 5.338V10H13.3333V2.66667H6V4.66667Z"
        fill="#128555"
      />
    </svg>
  );
};
