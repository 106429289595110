export const IsFavoriteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12.8333C3.77825 12.8333 1.16666 10.2218 1.16666 7C1.16666 3.77825 3.77825 1.16667 7 1.16667C10.2217 1.16667 12.8333 3.77825 12.8333 7C12.8333 10.2218 10.2217 12.8333 7 12.8333ZM6.41841 9.33333L10.5426 5.20858L9.71775 4.38375L6.41841 7.68367L4.76816 6.03342L3.94333 6.85825L6.41841 9.33333Z"
        fill="#161e41"
      />
    </svg>
  );
};
