export const RoomsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 14L1.33333 12.6667H2.66667V3.22333C2.66667 2.90067 2.89733 2.62467 3.214 2.56733L9.52867 1.41933C9.746 1.37933 9.954 1.524 9.99333 1.74133C9.998 1.76467 10 1.788 10 1.81267V2.666L12.6667 2.66667C13.0347 2.66667 13.3333 2.96533 13.3333 3.33333V12.6667H14.6667V14H12V4H10V14H1.33333ZM8.66667 2.93067L4 3.78V12.6667H8.66667V2.93067ZM8 7.33333V8.66667H6.66667V7.33333H8Z"
        fill="#128555"
      />
    </svg>
  );
};
