import { getSeoMetatagsById } from 'api';
import { useQuery } from 'react-query';

export const useGetSeoMetadataByIdQuery = (options) => {
  return useQuery(
    ['seoMetatagsById', options.id],
    () => getSeoMetatagsById(options),
    {
      refetchOnWindowFocus: false,
    },
  );
};
