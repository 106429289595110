export const AddIcon = ({ fill = '#161E41' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 9.16667V5.83333H10.8333V9.16667H14.1667V10.8333H10.8333V14.1667H9.16667V10.8333H5.83333V9.16667H9.16667ZM10 18.3333C5.3975 18.3333 1.66667 14.6025 1.66667 10C1.66667 5.3975 5.3975 1.66667 10 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 10 18.3333ZM10 16.6667C11.7681 16.6667 13.4638 15.9643 14.714 14.714C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23189 15.9643 6.5362 14.714 5.28596C13.4638 4.03571 11.7681 3.33333 10 3.33333C8.23189 3.33333 6.5362 4.03571 5.28596 5.28596C4.03571 6.5362 3.33333 8.23189 3.33333 10C3.33333 11.7681 4.03571 13.4638 5.28596 14.714C6.5362 15.9643 8.23189 16.6667 10 16.6667V16.6667Z"
        fill={fill}
      />
    </svg>
  );
};
