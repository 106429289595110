import styled from 'styled-components';

export const DropZoneWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.circle ? '76px' : '810px')};
  max-height: ${(props) =>
    props.circle ? '76px' : props.multiple ? 'auto' : '380px'};
  margin-top: ${(props) => (props.circle ? '16px' : '26px')};
  background: #ffffff;
  border-radius: ${(props) => (props.circle ? '1000px' : '12px')};
  padding: 0;

  @media (max-width: 768px) {
    max-height: ${(props) =>
      props.circle ? '76px' : props.multiple ? 'auto' : '197px'};
  }
`;

export const DropBackgroundImg = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.circle ? '76px' : '810px')};
  height: ${(props) => (props.circle ? '76px' : 'auto')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.circle ? '#9B9EA7' : props.single ? '#e2ffe7' : '#EEF2FA'};
  border: ${(props) =>
    props.hasError
      ? '1px solid red'
      : props.circle
      ? '1px solid #9B9EA7'
      : props.single
      ? '1px solid #128555'
      : '1px solid #778DC9'};
  border-radius: ${(props) => (props.circle ? '1000px' : '8px')};
  padding: 16px 12px;
`;

export const DropText = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #161e41;
  margin-top: 10px;
`;

export const DropzoneImg = styled.div`
  width: 100%;
  border: ${(props) =>
    props.isVideo || props.isSvg ? '1px solid #000' : 'none'};
  border-radius: ${(props) => (props.circle ? '1000px' : '8px')};
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: ${(props) => (props.isVideo || props.isSvg ? '16px' : '0')};
`;

export const DeleteBox = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
`;

export const StyledImage = styled.img`
  width: ${(props) => (props.isSvg ? 'auto' : '100%')};
  object-fit: ${(props) =>
    props.isSvg ? 'initial' : props.isVideo ? 'none' : 'cover'};
  height: ${(props) =>
    props.circle ? '78px' : props.isVideo || props.isSvg ? '167px' : '380px'};

  @media (max-width: 768px) {
    height: ${(props) =>
      props.circle ? '78px' : props.isVideo || props.isSvg ? '167px' : '197px'};
  }
`;

export const DefaultContent = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const MultipleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TitlePhoto = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

export const MultipleImage = styled.img`
  width: 100%;
  max-width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  @media (max-width: 768px) {
    max-width: 72px;
    height: 72px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

export const PhotosBlock = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 18px;

  @media (max-width: 768px) {
    gap: 9px;
  }
`;

export const AddMultipleNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  width: 100%;
  max-width: 189px;
  height: 136px;
  background: #778dc9;
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: 768px) {
    max-width: 79px;
    height: 72px;
  }
`;

export const HelperTextPhoto = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: ${(props) => (props.hasError ? 'red' : '#9b9ea7')};
  margin-top: 16px;
  max-width: 242px;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 14px;
    margin-top: 8px;
  }
`;

export const EditPhotoBox = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  cursor: pointer;
`;

export const MultipleImageBlock = styled.img`
  width: 100%;
  max-width: 189px;
  height: 136px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: 79px;
    height: 72px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 189px;
  height: 136px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  @media (max-width: 768px) {
    max-width: 79px;
    height: 72px;
  }
`;

export const DeleteBoxMultiple = styled.div`
  position: absolute;
  bottom: 6px;
  right: 14px;
  cursor: pointer;

  svg {
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    bottom: 0;
    right: 8px;
  }
`;
