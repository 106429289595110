export const DeleteIcon = ({ width = '42', height = '42' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="8" fill="#161E41" />
      <path
        d="M17.6667 15.6667V14.3333H24.3333V15.6667H27.6667V17H26.3333V27C26.3333 27.1768 26.2631 27.3464 26.1381 27.4714C26.013 27.5964 25.8435 27.6667 25.6667 27.6667H16.3333C16.1565 27.6667 15.987 27.5964 15.8619 27.4714C15.7369 27.3464 15.6667 27.1768 15.6667 27V17H14.3333V15.6667H17.6667ZM17 17V26.3333H25V17H17ZM19 19H20.3333V24.3333H19V19ZM21.6667 19H23V24.3333H21.6667V19Z"
        fill="white"
      />
    </svg>
  );
};
