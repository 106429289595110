import { PATHS } from 'constants';
import { routes } from 'constants/routes';
import Layout from 'layouts';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const renderRoutes = (_routes) =>
  _routes.map((route) => {
    const { path, component: Component } = route;
    return (
      <Route
        key={path}
        path={path}
        element={<Component />}
        index={path === '/'}
      />
    );
  });

export default function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {renderRoutes(routes)}
      </Route>
      <Route path="*" element={<Navigate replace to={PATHS.home} />} />
    </Routes>
  );
}
