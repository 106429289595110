export const getInitEditFields = ({
  announcementInfo,
  name,
  languages,
  isArray,
  sortName,
}) => {
  const obj = {};
  languages?.forEach((lang) => {
    announcementInfo?.forEach((item) => {
      if (lang.id === item.languageId) {
        isArray
          ? (obj[`${name}${lang?.iso}`] = Array.from(item.tags)
              .filter((item) => item.groupName === sortName)
              .map((item) => {
                return {
                  groupName: item.groupName,
                  name: item.name,
                  iconToken: item.iconToken,
                  tagTemplateId: item.tagTemplateId,
                };
              }))
          : (obj[`${name}${lang?.iso}`] = item[name]);
      }
    });
  });
  return obj;
};
