import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon, ConfirmIcon } from 'assets';
import Loader from 'components/Loader';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { sampleCustomFieldsInfo } from 'connectors/sampleCustomFieldsInfo';
import { getSectionOptions } from 'connectors/sectionsOptions';
import { SUCCESS_MESSAGES, ERRORS_MESSAGES, PATHS } from 'constants';
import {
  useAddTemplates,
  useEditTemplates,
  useGetLanguagesQuery,
  useGetSectionQuery,
  useGetTemplateByIdQuery,
} from 'hooks';
import { getSampleCustomFieldsData } from 'hooks/getSampleCustomFieldsData';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { disableEnterSubmit, nameIsUsedError } from 'utils';
import { addEditSampleCustomFields } from 'validations/schemas/addEditSampleCustomFields';
import {
  ActionButtons,
  AddEditSampleCustomFieldWrapper,
  ButtonText,
  CancelButton,
  ConfirmButton,
  CreateTitle,
  FieldsWrapper,
  LoaderWrapper,
  SampleInnerBlock,
  SampleWrapperBlock,
  TitleInner,
  TitleText,
} from './styles';

const AddEditSampleCustomField = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location?.state?.isEditMode;

  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const { data: sections, isLoading: loadingSections } = useGetSectionQuery();
  const sectionOptions = getSectionOptions({ sections });

  const {
    data: template,
    isLoading: loadingTemplateQuery,
    refetch,
  } = useGetTemplateByIdQuery({
    id: location?.state?.customFieldId,
  });

  const {
    ADD_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
    EDIT_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
  } = getSampleCustomFieldsData({ languages, template, sections });

  const methods = useForm({
    defaultValues: isEditMode
      ? EDIT_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS
      : ADD_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
    resolver: yupResolver(addEditSampleCustomFields()),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const errorFieldsToast = () => {
    Object.keys(errors).length &&
      toast.error(ERRORS_MESSAGES.ERRORS_FIELDS_REQUIRED_NOLANG, {
        position: 'bottom-left',
      });
  };

  const handleResetForm = () => {
    reset(
      isEditMode
        ? EDIT_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS
        : ADD_INITIAL_VALUES_SAMPLES_CUSTOM_FIELDS,
    );
  };

  const { mutateAsync: addTemplates, isLoading: loadingAddAgent } =
    useAddTemplates({
      onSuccess() {
        toast.success(SUCCESS_MESSAGES.ADD_TEMPLATE_SUCCESS, {
          position: 'bottom-left',
        });
        handleResetForm();
      },
      onError(error) {
        toast.error(nameIsUsedError(error), {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: editTemplate, isLoading: loadingEditAgent } =
    useEditTemplates({
      onSuccess() {
        refetch();
        toast.success(SUCCESS_MESSAGES.EDIT_TEMPLATE_SUCCESS, {
          position: 'bottom-left',
        });
        handleResetForm();
      },
      onError(error) {
        toast.error(nameIsUsedError(error), {
          position: 'bottom-left',
        });
      },
    });

  const onSubmit = (data) => {
    isEditMode
      ? editTemplate(sampleCustomFieldsInfo({ data, languages, isEditMode }))
      : addTemplates(sampleCustomFieldsInfo({ data, languages, isEditMode }));
  };

  useEffect(() => {
    handleResetForm();
  }, [isEditMode, template]);

  useEffect(() => {
    errorFieldsToast();
  }, [errors]);

  const handleCancelTemplate = () => {
    return navigate(`${PATHS.samplesCustomFields}`);
  };

  if (loadingTemplateQuery || loadingLanguages || loadingSections) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={disableEnterSubmit}
        noValidate
      >
        <AddEditSampleCustomFieldWrapper>
          <CreateTitle>
            <TitleInner addEdit>
              <TitleText>
                {isEditMode ? 'Редактирование шаблона' : 'Создание шаблона'}
              </TitleText>
              <Link to={PATHS.samplesCustomFields}>
                <CloseIcon />
              </Link>
            </TitleInner>
          </CreateTitle>
          <SampleWrapperBlock>
            <SampleInnerBlock>
              <Select
                name="sectionName"
                label={'Название секции *'}
                options={sectionOptions}
              />
              <TextField
                name={`templateName`}
                label={'Имя шаблона*'}
                placeholder={'Введите заголовок'}
                type="text"
              />
              {languages
                ?.sort((lang) => lang.id)
                ?.map((lang) => {
                  return (
                    <FieldsWrapper key={lang?.id}>
                      <TextField
                        name={`key${lang.iso}`}
                        label={`Название ${lang.name} *`}
                        placeholder={'Введите название'}
                        type="text"
                      />
                      <TextField
                        name={`value${lang.iso}`}
                        label={`Значение ${lang.name} *`}
                        placeholder={'Введите значение'}
                        type="text"
                      />
                    </FieldsWrapper>
                  );
                })}
            </SampleInnerBlock>
          </SampleWrapperBlock>
          <ActionButtons>
            <CancelButton
              type="button"
              onClick={handleCancelTemplate}
              disabled={loadingAddAgent || loadingEditAgent}
            >
              <CloseIcon fill={'#9B9EA7'} />
              <ButtonText>Отменить</ButtonText>
            </CancelButton>
            <ConfirmButton
              type="submit"
              disabled={loadingAddAgent || loadingEditAgent}
            >
              {loadingAddAgent || loadingEditAgent ? (
                <Loader width={'18px'} height={'18px'} fill={'#fff'} />
              ) : (
                <>
                  <ConfirmIcon />
                  <ButtonText active>
                    {isEditMode ? 'Сохранить' : 'Добавить'}
                  </ButtonText>
                </>
              )}
            </ConfirmButton>
          </ActionButtons>
        </AddEditSampleCustomFieldWrapper>
      </form>
    </FormProvider>
  );
};

export default AddEditSampleCustomField;
