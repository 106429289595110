import styled from 'styled-components';

export const CustomTextArea = styled.textarea`
  display: flex;
  padding: 16px 12px;
  width: 100%;
  height: 100px;
  border: ${(props) =>
    props.hasError ? '1px solid red' : '1px solid #dbdfe8'};
  border-radius: 6px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #161e41;
  resize: none;

  :focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #dbdfe8;
  }
`;

export const Label = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #9b9ea7;
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 124px;
  margin-top: 16px;
`;

export const TextAreaBlock = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
