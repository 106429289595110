import { getTemplateById } from 'api';
import { useQuery } from 'react-query';

export const useGetTemplateByIdQuery = (options) => {
  return useQuery(
    ['templateById', options.id],
    () => getTemplateById(options),
    {
      refetchOnWindowFocus: false,
    },
  );
};
